import { combineReducers } from 'redux';
import { UserProfileActions } from 'actions/actions-v2/user-profile-action-v2';
import { IPerson } from 'models/person';
import { IAppAction } from 'actions/app-action';
import { AppActions } from 'actions/app-actions';
import { IPersonAssignedSite } from 'models/site-profile';

export interface IPersonProfileState {
    personProfile: IPerson;
    isLoading: boolean;
    isLoadingSite: boolean;
    showRoleSelectorDialog: boolean;
    signOffAfterRoleUpdate: boolean;
    myAssignedSites: IPersonAssignedSite[];
    showOperatingPlatformSelectorDialog: boolean;
}

const INITIAL_STATE: IPersonProfileState = {
    personProfile: {
        id: '',
        siteId: '',
        bucket: 'Person',
        shard: 'Person',
        eTag: '',
        personReference: null,
        firstName: '',
        lastName: '',
        fullName: '',
        preferredName: '',
        businessTitle: '',
        emailAddress1: '',
        departmentId: 0,
        departmentDescription: '',
        supervisorId: 0,
        supervisorName: '',
        telephone1: '',
        telephone2: '',
        isActive: true,
        isExternal: false,
        importedFromMyHr: false,
        roles: [],
        createdDateTime: null,
        lastUpdatedDateTime: null,
        createdByPersonId: null,
        lastUpdatedByPersonId: null,
        notify: true,
        notifyEmail: null,
        language: 'en-GB',
        isLanguageChange: false,
        selectedSite: '1',
        isShowFaqs: true,
        snowTicket: '',
        preferredRole: null,
        siteCount: 0,
        accessRights: {
            operatingPlatforms: [],
            linesOfBusiness: [],
            divisions: [],
            businessUnits: [],
            clients: [],
            sites: [],
        },
    },
    isLoading: false,
    isLoadingSite: false,
    showRoleSelectorDialog: false,
    showOperatingPlatformSelectorDialog: false,
    signOffAfterRoleUpdate: false,
    myAssignedSites: [],
};

const personProfile = (
    state: IPersonProfileState['personProfile'] = INITIAL_STATE.personProfile,
    action: IAppAction
) => {
    switch (action.type) {
        case UserProfileActions.LOAD_PERSON_FULFILLED:
            return action.payload ? action.payload : state;
        case UserProfileActions.UPDATE_LANGUAGE_FULFILLED:
        case UserProfileActions.UPDATE_LANGUAGE:
        case UserProfileActions.UPDATE_IS_SHOW_FAQ:
        case UserProfileActions.UPDATE_IS_SHOW_FAQ_FULFILLED:
        case UserProfileActions.UPDATE_PREFERRED_ROLE_FULFILLED:
        case UserProfileActions.UPDATE_PREFERRED_ROLE:
            return action.payload ? action.payload : state;
        case UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM_FULFILLED:
        case UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM:
            return action.payload ? action.payload : state;
        case AppActions.CHANGE_SITE:
            return { ...state, selectedSite: action.payload.siteId };
        case AppActions.CHANGE_SITE_FULFILLED:
            if (action.payload) {
                return { ...state, selectedSiteEngineerId: action.payload.selectedSiteEngineerId };
            }
            return state;
        default:
            return state;
    }
};

const isLoading = (
    state: IPersonProfileState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case UserProfileActions.LOAD_PERSON:
        case UserProfileActions.UPDATE_PERSON:
        case UserProfileActions.LOAD_MY_ASSIGNED_SITES:
        case UserProfileActions.UPDATE_PREFERRED_ROLE:
            return true;
        case UserProfileActions.LOAD_PERSON_FULFILLED:
        case UserProfileActions.LOAD_PERSON_REJECTED:
        case UserProfileActions.UPDATE_PERSON_FULFILLED:
        case UserProfileActions.UPDATE_PERSON_REJECTED:
        case UserProfileActions.LOAD_MY_ASSIGNED_SITES_FULFILLED:
        case UserProfileActions.LOAD_MY_ASSIGNED_SITES_REJECTED:
        case UserProfileActions.UPDATE_PREFERRED_ROLE_FULFILLED:
        case UserProfileActions.UPDATE_PREFERRED_ROLE_REJECTED:
            return false;
        default:
            return state;
    }
};

const isLoadingSite = (
    state: IPersonProfileState['isLoadingSite'] = INITIAL_STATE.isLoadingSite,
    action: IAppAction
) => {
    switch (action.type) {
        case AppActions.CHANGE_SITE:
            return true;
        case AppActions.CHANGE_SITE_FULFILLED:
        case AppActions.CHANGE_SITE_REJECTED:
            return false;
        default:
            return state;
    }
};

const showRoleSelectorDialog = (
    state: IPersonProfileState['showRoleSelectorDialog'] = INITIAL_STATE.showRoleSelectorDialog,
    action: IAppAction
) => {
    switch (action.type) {
        case UserProfileActions.SHOW_ROLE_SELECTOR_DIALOG:
            return action.payload;
        default:
            return state;
    }
};

const signOffAfterRoleUpdate = (
    state: IPersonProfileState['signOffAfterRoleUpdate'] = INITIAL_STATE.signOffAfterRoleUpdate,
    action: IAppAction
) => {
    switch (action.type) {
        case UserProfileActions.UPDATE_PREFERRED_ROLE:
        case UserProfileActions.UPDATE_PREFERRED_ROLE_REJECTED:
            return false;
        case UserProfileActions.UPDATE_PREFERRED_ROLE_FULFILLED:
            return true;
        default:
            return state;
    }
};

const myAssignedSites = (state: IPersonProfileState['myAssignedSites'] = INITIAL_STATE.myAssignedSites, action: IAppAction) => {
    switch (action.type) {
        case UserProfileActions.LOAD_MY_ASSIGNED_SITES_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const showOperatingPlatformSelectorDialog = (
    state: IPersonProfileState['showOperatingPlatformSelectorDialog'] = INITIAL_STATE.showOperatingPlatformSelectorDialog,
    action: IAppAction
) => {
    switch (action.type) {
        case UserProfileActions.SHOW_OPERATING_PLATFORM_SELECTOR_DIALOG:
            return action.payload;
        default:
            return state;
    }
};

export const reducer = combineReducers<IPersonProfileState>({
    personProfile,
    isLoading,
    isLoadingSite,
    showRoleSelectorDialog,
    signOffAfterRoleUpdate,
    myAssignedSites,
    showOperatingPlatformSelectorDialog
});
