import { IKeyedItem } from '../keyed-item';
import { IAuditableItem } from '../auditable-item';
import { isEmpty } from 'lodash';
import { ShiftHandoverType } from '../site-profile';
import { ILookupModel } from '../lookup-model';

export interface IShiftHandover extends IKeyedItem, IAuditableItem {
    checkListItemsVersion: string;
    shiftId: string;
    shiftName: string;
    siteId: string;
    facilityId: string;
    facilityName: string;
    checkListItems: IShiftChecklistItem[];
    handoverItems: IShiftHandoverItem[];
    documents: string[];
    status: ShiftHandoverStatus;
    dateOfShiftHandover: Date | string;
    notes: string;
    hasGridNotes: boolean;
    gridRows: number;
    gridColumns: number;
    gridNotes: string[];
    createdByPersonId: string;
    checkListItemsVersions: string[];
}

export interface IShiftHandoverItem {
    id: string;
    description: string;
    category: HandoverItemCategory;
    status: HandoverItemStatus;
    closeNotes: string;
    dateOfItemRaised: Date | string;
    dateOfItemClosed: Date | string;
}

export interface IShiftChecklistItem {
    id: string;
    status: boolean | null;
    comments: string;
    siteArea: string;
    checkListItemsVersion: string;
}

export interface IShiftHandoverFilters {
    siteId: string;
    facilityId?: string;
    startDate: Date | string;
    endDate: Date | string;
}

export interface IShiftHandoverSettings {
    siteId: string;
    shiftHandoverType: ShiftHandoverType;
    hasActiveShift: boolean;
    shifts: ILookupModel[];
    facilities: ILookupModel[];
    restrictAcknowledgement?: boolean;
}
export interface IShiftHandoverCategories {
    siteId: string;
    categories: IShiftHandoverItemCategory[];
}

export interface IShiftHandoverOperatingPlatformCategories {
    operatingPlatform: string;
    categories: IShiftHandoverItemCategory[];
}

export interface IShiftHandoverItemCategory {
    id: string;
    name: string;
    isDefault: boolean;
    isEnabled: boolean;
    operatingPlatform?: string;
}

export const getOption = (facility: ILookupModel) => ({
    value: facility.id,
    label: facility.name,
});

export const selectedFacilityValue = (facilities: ILookupModel[], selectedFacilityId: string) => {
    if (!selectedFacilityId || isEmpty(facilities)) {
        return null;
    }

    const facility = facilities.find((f) => f.id === selectedFacilityId);
    if (!facility) {
        return null;
    }

    return getOption(facility);
};

export enum ShiftHandoverStatus {
    Awaiting = 'Awaiting',
    Acknowledged = 'Acknowledged',
}

export enum HandoverItemCategory {
    Vendors = 'Vendor on site',
    ActiveLocksOut = 'Active Locks Out',
    PermitsToCloseRenew = 'Permits to close and renew during shift handover',
    Other = 'Other',
}

export enum HandoverItemStatus {
    Closed = 'Closed',
    Ongoing = 'Ongoing',
}
