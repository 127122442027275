import { loadRelationships } from 'actions/actions-v2/person-action-v2';
import {
    updatePerson,
    loadPerson,
    updateLanguage,
    loadMyAssignedSites,
} from 'actions/actions-v2/user-profile-action-v2';

export default {
    updatePerson,
    loadPersonProfile: loadPerson,
    updateLanguage,
    loadRelationships,
    loadMyAssignedSites,
};
