import * as React from 'react';
import { FormikProps } from 'formik';
import { object, string, date } from 'yup';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { ISelectOption } from 'models/select-option';
import { Loader } from 'components/loader';
import {
    IShiftHandover,
    IShiftHandoverSettings,
    getOption,
    selectedFacilityValue,
    IShiftHandoverCategories,
} from 'models/shifts/shift-handover';
import { IShiftChecklist } from 'models/shifts/master-shift-checklist';
import { ShiftHandoverItemsTable } from 'components/shifts/shift-handover-items-table';
import { ShiftHandoverChecklistTable } from 'components/shifts/shift-handover-checklist-table';
import { ShiftHandoverType } from 'models/site-profile';
import { SelectField } from 'components/form/fields/select-field';
import useTranslate from 'translations/translation-utils';
import SouTable from '@cbre/react-spreadsheet-component';
import '@cbre/react-spreadsheet-component/SouTable.css'
import { Checkbox } from 'components/form/Checkbox';
import { ConfirmDialog } from 'components/v2/components';
import { Field } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';




interface IProps {
    isLoading: boolean;
    formikBag: FormikProps<IShiftHandover>;
    shiftChecklist: IShiftChecklist[];
    shiftHandoverSettings: IShiftHandoverSettings;
    shiftHandoverItemCategories: IShiftHandoverCategories;
}

export const shiftHandoverDetailsFormValidationSchema = object<Partial<IShiftHandover>>().shape({
    shiftId: string().required('Shift is required'),
    dateOfShiftHandover: date().required('Date is required'),
});

export const ShiftHandoverDetailsTab: React.FC<IProps> = ({
    isLoading,
    formikBag,
    shiftChecklist,
    shiftHandoverSettings,
    shiftHandoverItemCategories,
}) => {
    const handleShiftChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('shiftId', field.value);
        formikBag.setFieldValue('shiftName', field.label);
        formikBag.setTouched(formikBag.touched);
    };

    const onFacilityChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('facilityId', field.value);
        formikBag.setFieldValue('facilityName', field.label);
    };

    const handleDateChange = (newDate: Date) => {
        formikBag.setFieldValue('dateOfShiftHandover', newDate);
    };

    const facilities = shiftHandoverSettings.facilities;
    const shiftHandoverType = shiftHandoverSettings.shiftHandoverType;
    const getShifts =
        shiftHandoverSettings &&
        shiftHandoverSettings.shifts &&
        shiftHandoverSettings.shifts.map(
            (item) => ({ label: item.name, value: item.id } as ISelectOption)
        );

    const translate = useTranslate();
    const [showGridConfirmDialog, setShowGridConfirmDialog] = React.useState(false)

    const onShowGrid = () => {
        if (formikBag.values.hasGridNotes) {
            if (formikBag.values.gridNotes && formikBag.values.gridNotes.length > 0) {
                setShowGridConfirmDialog(true)
                return
            }
        }
        else {
            if (formikBag.values.notes && formikBag.values.notes != '') {
                setShowGridConfirmDialog(true)
                return
            }
        }
        formikBag.setFieldValue('hasGridNotes', !formikBag.values.hasGridNotes)
    }

    const clearGrid = () => {
        if (formikBag.values.hasGridNotes) {
            formikBag.setFieldValue('gridColumns', 0)
            formikBag.setFieldValue('gridRows', 0)
            formikBag.setFieldValue('gridNotes', [])
        }
        else {
            formikBag.setFieldValue('notes', '')
        }
        formikBag.setFieldValue('hasGridNotes', !formikBag.values.hasGridNotes)
        setShowGridConfirmDialog(false)
    }

    const getGridData = (): string[] => {
        let col = formikBag.values.gridColumns
        let row = formikBag.values.gridRows
        let gridNotes = [];
        for (let c = 0; c < col; c++) {
            let cells = []
            for (let r = 0; r < row; r++) {
                cells.push(formikBag.values.gridNotes[(c * row) + r])
            }
            gridNotes.push(cells)
        }
        setRowHeight()
        return gridNotes
    }

    const setGridData = (data: any) => {
        let col = data.length
        let row = data.length > 0 ? data[0].length : 0
        let gridNotes = [];
        for (let c = 0; c < col; c++) {
            for (let r = 0; r < row; r++) {
                gridNotes.push(data[c][r])
            }
        }
        formikBag.setFieldValue('gridColumns', col)
        formikBag.setFieldValue('gridRows', row)
        formikBag.setFieldValue('gridNotes', gridNotes)
    }

    const setRowHeight = () => {
        let tables1 = document.getElementsByClassName("sou-table");
        let tables2 = document.getElementsByClassName("sou-table-left-header");
        if (tables1.length < 2 || tables2.length < 1) return;
        let rows1 = tables1[1].getElementsByTagName("tr")
        let rows2 = tables2[0].getElementsByTagName("tr")
        if (!(rows1 && rows2))
            return
        for (let i in rows1) {
            if (rows1[i].cells) {
                rows2[parseInt(i, 10) + 1].cells[0].style.height = "" + rows1[i].cells[0].offsetHeight + "px"
            }
        }
    }

    return (
        <div className="tabs-container tab-content">
            <Loader loading={isLoading}>
                <div className="columns">
                    <div className="column is-4">
                        <SelectField
                            id="shiftId"
                            label={translate('ShiftHandoverDetailsTab.Fields.ShiftName.label')}
                            value={{
                                value: formikBag.values.shiftId,
                                label: formikBag.values.shiftName,
                            }}
                            error={formikBag.errors.shiftId}
                            handleChange={handleShiftChange}
                            handleBlur={formikBag.handleBlur}
                            options={getShifts}
                        />
                    </div>
                    <div className="column is-4">
                        <DatePickerField
                            id="dateOfShiftHandover"
                            label={translate('ShiftHandoverDetailsTab.Fields.ShiftDay.label')}
                            placeholderText={translate(
                                'ShiftHandoverDetailsTab.Fields.ShiftDay.placeholder'
                            )}
                            value={formikBag.values.dateOfShiftHandover}
                            error={formikBag.errors.dateOfShiftHandover}
                            handleChange={handleDateChange}
                            handleBlur={formikBag.handleBlur}
                        />
                    </div>
                    {shiftHandoverType === ShiftHandoverType.Facility && (
                        <div className="column is-4">
                            <SelectField
                                id="facilityId"
                                label={translate('ShiftHandoverDetailsTab.Fields.Facility.label')}
                                className="select-facility"
                                value={selectedFacilityValue(
                                    facilities,
                                    formikBag.values.facilityId
                                )}
                                options={facilities.map((f) => getOption(f))}
                                handleChange={onFacilityChange}
                            />
                        </div>
                    )}
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <label className="label">{translate('ShiftHandoverDetailsTab.Fields.ShiftNotes.label')}</label>
                    </div>
                    <div className="column">
                        <Field
                            isHorizontal={true}
                            htmlFor="displayGrid"
                            label={translate('ShiftHandoverDetailsTab.Fields.DisplayGrid.label')}
                            labelSize={BulmaSize.Default}
                        >
                            <Checkbox
                                id="displayGrid"
                                checked={formikBag.values.hasGridNotes}
                                onChange={() => onShowGrid()}
                            />
                        </Field>
                    </div>
                </div>
                {!(formikBag.values.hasGridNotes && formikBag.values.hasGridNotes == true) &&
                    <div className="columns">
                        <div className="column">
                            <TextAreaField
                                id="notes"
                                label={""}
                                value={formikBag.values.notes}
                                error={formikBag.errors.notes}
                                handleChange={formikBag.handleChange}
                                handleBlur={formikBag.handleBlur}
                            />
                        </div>
                    </div>
                }
                {(formikBag.values.hasGridNotes && formikBag.values.hasGridNotes == true) &&
                    <div className="columns">
                        <div className="column">
                            <SouTable
                                tableData={getGridData()}
                                width={1100}
                                height={350}
                                minTableCol={10}
                                minTableRow={20}
                                minCellWidth={150}
                                cellHeight={28}
                                getData={function (data) {
                                    setGridData(data)
                                }}
                            />
                        </div>
                    </div>
                }
                <ShiftHandoverChecklistTable
                    formikBag={formikBag}
                    shiftChecklist={shiftChecklist}
                />
                <ShiftHandoverItemsTable formikBag={formikBag} shiftHandoverItemCategories={shiftHandoverItemCategories} />
                <ConfirmDialog
                    title={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Confirm')}
                    message={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Info')}
                    buttonCancelHide={false}
                    buttonConfirmText={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Confirm')}
                    isVisible={showGridConfirmDialog}
                    onConfirm={clearGrid}
                    onClose={() => setShowGridConfirmDialog(false)}
                    onOutsideDialogClick={() => setShowGridConfirmDialog(false)}
                />
            </Loader>
        </div>
    );
};
