import * as React from 'react';
import { OperatingPlatform } from '../../../models/site-profile';
import useTranslate from 'translations/translation-utils';

interface IStateProps {
    operatingPlatform: string;
    isTestSite: boolean;
}

const DashboardPortfolioOverview: React.FC<IStateProps> = (props) => {
    const buttonCssClasses = 'button is-primary is-fullwidth is-medium';
    const siteReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/155688';
    const riskReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/183329';
    const actionReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/177140';
    const incidentReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/174432';
    const cermComplianceReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/217742';
    const riskHERMReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/185287';
    const actionHERMReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/215678';
    const overviewHERMReport = 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/222508';
    const riskUrl = '/Risks';
    const actionUrl = '/Actions';
    const translate = useTranslate();

    const renderRiskLink = (href: string, target: string) => (
        <div className="column is-narrow is-6-mobile">
            <div className="has-text-centered">
                <a
                    href={href}
                    className={buttonCssClasses}
                    target={target}
                    rel="noopener noreferrer"
                >
                    {translate('Dashboard.PortfolioOverview.ViewRisks')}
                </a>
            </div>
        </div>
    );

    const renderActionLink = (href: string, target: string) => (
        <div className="column is-narrow is-6-mobile">
            <div className="has-text-centered">
                <a
                    href={href}
                    className={buttonCssClasses}
                    target={target}
                    rel="noopener noreferrer"
                >
                    {translate('Dashboard.Action.ViewActions')}
                </a>
            </div>
        </div>
    );

    return (
        <div className="dashboard-top-buttons">
            <div className="columns is-variable is-1">
                <div className="column is-2 hide-top-buttons-heading">
                    <div className="columns">
                        <h2>{translate('Dashboard.PortfolioOverview.Heading')}</h2>
                    </div>
                </div>

                <div className="column">
                    <div className="columns is-mobile is-multiline">
                        {(props.operatingPlatform === OperatingPlatform.DataCenter) &&
                            (<div className="column is-narrow is-6-mobile">
                                <div className="has-text-centered">
                                    <a
                                        href={siteReport}
                                        className={buttonCssClasses}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {translate('Dashboard.PortfolioOverview.ViewSites')}
                                    </a>
                                </div>
                            </div>)
                        }

                        <>
                            {props.operatingPlatform === OperatingPlatform.DataCenter &&
                                renderRiskLink(riskReport, "_blank")}
                            {props.operatingPlatform === OperatingPlatform.Healthcare &&
                                renderRiskLink(
                                    props.isTestSite ? riskUrl : riskHERMReport,
                                    props.isTestSite ? undefined : "_blank"
                                )}
                        </>

                        <>
                            {props.operatingPlatform === OperatingPlatform.DataCenter &&
                                renderActionLink(actionReport, "_blank")}
                            {props.operatingPlatform === OperatingPlatform.Healthcare &&
                                renderActionLink(
                                    props.isTestSite ? actionUrl : actionHERMReport,
                                    props.isTestSite ? undefined : "_blank"
                                )}
                        </>

                        {
                            (props.operatingPlatform === OperatingPlatform.Healthcare) &&
                            (<div className="column is-narrow is-6-mobile">
                                <div className="has-text-centered">
                                    <a
                                        href={overviewHERMReport}
                                        className={buttonCssClasses}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {translate('Dashboard.PortfolioOverview.ViewHERMOverview')}
                                    </a>
                                </div>
                            </div>)
                        }

                        {props.operatingPlatform === OperatingPlatform.DataCenter && (<div className="column is-narrow is-6-mobile">
                            <div className="has-text-centered">
                                <a
                                    href={incidentReport}
                                    className={buttonCssClasses}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {translate('Dashboard.Incidents.View')}
                                </a>
                            </div>
                        </div>)}

                        {props.operatingPlatform === OperatingPlatform.DataCenter && (<div className="column is-narrow is-6-mobile">
                            <div className="has-text-centered">
                                <a
                                    href={cermComplianceReport}
                                    className={buttonCssClasses}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {translate('Dashboard.PortfolioOverview.ViewCERM')}
                                </a>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPortfolioOverview;
