import * as React from 'react';
import { FormikProps } from 'formik';
import { Loader } from 'components/loader';
import { IShiftHandover, IShiftHandoverCategories, IShiftHandoverSettings } from 'models/shifts/shift-handover';
import { IShiftChecklist } from 'models/shifts/master-shift-checklist';
import { ShiftHandoverItemsTable } from 'components/shifts/shift-handover-items-table';
import { ShiftHandoverChecklistTable } from 'components/shifts/shift-handover-checklist-table';
import { ShiftHandoverType } from 'models/site-profile';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { formatDateString } from 'utils/date-utils';
import useTranslate from 'translations/translation-utils';
import SouTable from '@cbre/react-spreadsheet-component';
import '@cbre/react-spreadsheet-component/SouTable.css'
import { TextAreaField } from 'components/form/fields/textarea-field';

interface IProps {
    isLoading: boolean;
    formikBag: FormikProps<IShiftHandover>;
    shiftChecklist: IShiftChecklist[];
    shiftHandoverSettings: IShiftHandoverSettings;
    shiftHandoverItemCategories: IShiftHandoverCategories;
}

export const ShiftHandoverDetailsReadOnlyTab: React.FC<IProps> = ({
    isLoading,
    formikBag,
    shiftChecklist,
    shiftHandoverSettings,
    shiftHandoverItemCategories
}) => {
    const shiftHandover = formikBag && formikBag.values;
    const shiftHandoverType = shiftHandoverSettings.shiftHandoverType;

    const translate = useTranslate();

    const getGridData = (): string[] => {
        let col = shiftHandover.gridColumns
        let row = shiftHandover.gridRows
        let gridNotes = [];
        for (let c = 0; c < col; c++) {
            let cells = []
            for (let r = 0; r < row; r++) {
                cells.push(shiftHandover.gridNotes[(c * row) + r])
            }
            gridNotes.push(cells)
        }
        return gridNotes
    }

    return (
        <div className="tabs-container tab-content">
            <Loader loading={isLoading}>
                <div className="columns">
                    <div className="column is-4">
                        <ReadOnlyField
                            id="shiftId"
                            label={translate(
                                'ShiftHandoverDetailsTab.ReadonlyFields.ShiftTitle.label'
                            )}
                            value={shiftHandover.shiftName}
                        />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField
                            id="dateOfShiftHandover"
                            label={translate(
                                'ShiftHandoverDetailsTab.ReadonlyFields.ShiftDay.label'
                            )}
                            value={formatDateString(
                                shiftHandover.dateOfShiftHandover,
                                'dddd D MMMM YYYY'
                            )}
                        />
                    </div>
                    {shiftHandoverType === ShiftHandoverType.Facility && (
                        <div className="column is-4">
                            <ReadOnlyField
                                id="facilityId"
                                label={translate(
                                    'ShiftHandoverDetailsTab.ReadonlyFields.Facility.label'
                                )}
                                value={shiftHandover.facilityName}
                            />
                        </div>
                    )}
                </div>
                {!(shiftHandover.hasGridNotes && shiftHandover.hasGridNotes == true) &&
                    <div className="columns">
                        <div className="column">
                            <TextAreaField
                                id="notes"
                                label={translate(
                                    'ShiftHandoverDetailsTab.ReadonlyFields.ShiftNotes.label'
                                )}
                                value={shiftHandover.notes}
                                handleChange={formikBag.handleChange}
                                isReadOnly={true}
                            />
                        </div>
                    </div>
                }
                {(shiftHandover.hasGridNotes && shiftHandover.hasGridNotes == true) &&
                    <div className="columns">
                        <div className="column">
                            <label className="label">{translate(
                                'ShiftHandoverDetailsTab.ReadonlyFields.ShiftNotes.label'
                            )}</label>
                        </div>
                    </div>
                }
                {(shiftHandover.hasGridNotes && shiftHandover.hasGridNotes == true) &&
                    <div className="columns">
                        <div className="column sou-table-readonly">
                            <SouTable
                                tableData={getGridData()}
                                width={1100}
                                height={350}
                                minTableCol={10}
                                minTableRow={20}
                                minCellWidth={150}
                                cellHeight={28}
                                getData={function () {
                                }}
                            />
                        </div>
                    </div>
                }
                <ShiftHandoverChecklistTable
                    formikBag={formikBag}
                    shiftChecklist={shiftChecklist}
                    isReadOnly={true}
                />
                <ShiftHandoverItemsTable formikBag={formikBag} isReadOnly={true} shiftHandoverItemCategories={shiftHandoverItemCategories} />
            </Loader>
        </div>
    );
};
