import * as React from 'react';
import { FieldArray, Formik, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { array, number, object, ref, string } from 'yup';
import { Loader } from 'components/loader';
import { ProgressBar } from 'components/progress-bar/progress-bar';
import { enumEnergyBillPeriods, IBuilding } from 'models/building';
import { IBenchmarkData } from 'models/pue-benchmarks';
import Questionnaire from 'routes/questionnaire/questionnaire-page';
import { IKeyValue } from '../../../models/key-value';
import { ISiteProfile, siteValidationGroup } from '../../../models/site-profile';
import { RouteUrlBuilding, RouteUrlSite, RouteUrlSiteSummary } from '../routes';
import { BuildingForm } from './building-form';
import { getProgressSections } from './common';
import { SiteForm } from './site-form';
import SiteSummary from './site-summary/site-summary';
import './upsert-site-page.scss';
import { IPersonLookup } from 'models/person-lookup';
import { SiteUsers } from './users';
import { IClientFilters } from 'models/client-filters';
import Page from 'components/v2/page/Page';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { SiteFacilitiesToolbar } from 'components/site-facilities/site-facilities-toolbar';
import { SystemIntegrationContainer } from './system-integration/system-integration-container';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ConfirmDialog } from 'components/v2/components';
import { FacilitySelectPage } from './facilitySelectModal';
import { IFacility } from 'models/facility-model';
import { IClient } from 'models/client';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IUpsertSitePageRouteParams {
    id: string;
    buildingIndex: string;
    energyBillIndex: string;
    section: string;
}

export interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    siteId: string;
    isLoading: boolean;
    pageTitle: string;
    attachments: IAttachmentModel[];
    permissions: Array<IKeyValue<string>>;
    initialValues?: Partial<ISiteProfile>;
    pueProjections: IBenchmarkData;
    selectedMonth: Date;
    personLookups: IPersonLookup[];
    onUpdate: (site: Partial<ISiteProfile>) => void;
    onCreate: (site: Partial<ISiteProfile>) => void;
    onSubmitEnergyBill: (site: Partial<ISiteProfile>) => void;
    onChangeRoot: (urlLocation: string) => void;
    onLoadSite: (id: string) => void;
    loadClients: (filters: IClientFilters) => void;
    loadFacility: () => void;
    showHelpModule: boolean;
    facilities: IFacility[];
    client: Partial<IClient>;
    operatingPlatforms: IOperatingPlatform[];
}

export interface IDispatchProps {
    onChangeRoot: (routeUrl: string) => void;
}

export const UpsertSitePage: React.FC<IProps & IDispatchProps> = (props) => {
    const translate = useTranslate();
    const [showHelp, setShowHelp] = React.useState(false);
    const [isErrorDialogVisible, setErrorDialogVisible] = React.useState(false);
    const [isFacilityDialogVisible, setIsFacilityDialogVisible] = React.useState(false);
    const customLinks: Array<IKeyValue<string>> = [{ key: '/Site', value: translate('UpsertSite.SiteForm.Breadcrumb') }];

    React.useEffect(() => {
        props.loadClients({ filterKeywords: [] });
        props.loadFacility();
        if (props.match.params.id && props.match.params.id !== "undefined") {
            props.onLoadSite(props.match.params.id);
        }
    }, [props.match.params.id]);

    const siteSchema = object<Partial<ISiteProfile>>().shape({
        name: string()
            .trim()
            .min(2, 'The site name must be at least 2 characters long.')
            .max(500, 'The site name cannot be more than 500 characters long.')
            .required('Please enter a Site Name')
            .nullable(true),
        clientId: string().required('Please select a client').nullable(true),
        cmms: string().nullable(true),
        lineOfBusiness: string().required('Please select a line of business').nullable(true),
        segment: string().nullable(true),
        totalMainsPowerCapacity: number().nullable(true),
        operatingPlatform: string().required('Please select a operating platform').nullable(true),
        riskPlatform: string().required('Please select primary risk management system').nullable(true),
        buildings: array()
            .min(1, 'At least 1 facility is required, please add a Facility')
            .of(
                object<Partial<IBuilding>>().shape({
                    buildingName: string()
                        .trim()
                        .min(2, 'The facility name must be at least 2 characters long.')
                        .max(500, 'The facility name cannot be more than 500 characters long.')
                        .required('Please enter facility name'),
                    region: string().required('Please select a region'),
                    country: string().required('Please select a country'),
                    facility: string().required('Please enter facility type'),
                    otherFacility: string().when('facility', (facilityValue: string) => {
                        if (facilityValue === 'Other') {
                            return string()
                                .nullable()
                                .test(
                                    'facilityOther',
                                    'Please enter facility type',
                                    (facilityOtherValue: string) =>
                                        facilityOtherValue &&
                                        facilityOtherValue !== undefined &&
                                        facilityOtherValue.length > 0
                                );
                        } else {
                            return string().notRequired().nullable(true);
                        }
                    }),
                    anyExistingEnvironmentalMonitoring: string().nullable(true),
                    anyExistingEnvironmentalMonitoringOther: string().when(
                        'anyExistingEnvironmentalMonitoring',
                        (anyExistingEnvironmentalMonitoringValue: string) => {
                            if (anyExistingEnvironmentalMonitoringValue === 'Other') {
                                return string()
                                    .nullable()
                                    .test(
                                        'anyExistingEnvironmentalMonitoringOther',
                                        'Please enter other environmental monitoring/control',
                                        (anyExistingEnvironmentalMonitoringOtherValue: string) =>
                                            anyExistingEnvironmentalMonitoringOtherValue &&
                                            anyExistingEnvironmentalMonitoringOtherValue !== undefined &&
                                            anyExistingEnvironmentalMonitoringOtherValue.length > 0
                                    );
                            } else {
                                return string().notRequired().nullable(true);
                            }
                        }
                    ),
                    totalSpace_SqFt: number().transform((value) => (isNaN(value) ? undefined : value)),
                    raisedFloorSpace_SqFt: number()
                        .lessThan(
                            ref('totalSpace_SqFt'),
                            'The data input is for raised floor space is above the total floor space. Please check the unit of measurement and update.'
                        )
                        .transform((value) => (isNaN(value) ? undefined : value)),
                })
            ),
    });

    const addMyDataFacilities = (
        formikBag: FormikProps<Partial<ISiteProfile>>,
        selectedFacilities: string[],
    ) => {
        if (selectedFacilities == null) {
            return;
        }
        let buildings = [...formikBag.values.buildings]
        if (buildings == null) {
            buildings = [];
        }

        selectedFacilities.forEach(selectedFacility => {
            const newFacilities = props.facilities?.filter(x => {
                return selectedFacility === x.propertyId;
            });
            if (newFacilities) {
                for (const f of newFacilities) {
                    const building: IBuilding = {
                        id: '',
                        siteId: '',
                        bucket: '',
                        shard: 'Building',
                        eTag: '',
                        clientFacilityId: '',
                        clientCode: null,
                        myDataPropertyId: null,
                        createdDateTime: undefined,
                        lastUpdatedDateTime: undefined,
                        createdByPersonId: undefined,
                        lastUpdatedByPersonId: undefined,
                        buildingCode: '',
                        buildingName: '',
                        siteCode: '',
                        country: '',
                        facility: '',
                        tier: '',
                        city: '',
                        county: '',
                        latitude: undefined,
                        region: '',
                        longitude: undefined,
                        isoCountryCode: '',
                        addressLine1: '',
                        addressLine2: '',
                        isMasterAddress: undefined,
                        areaCode: '',
                        isActive: undefined,
                        postCode: '',
                        raisedFloorSpace_SqFt: null,
                        totalSpace_SqFt: null,
                        powerUsageEffectiveness: undefined,
                        totalGeneratorPowerCapacity_MW: undefined,
                        upsPowerCapacity_MW: undefined,
                        itPowerCapacity_MW: undefined,
                        whiteSpaceFeet: undefined,
                        whitespaceMeters: undefined,
                        displayUnit: 'ft2',
                        questionnaireResult: 0,
                        energyBills: [],
                        weatherStationID: '',
                        energyBillPeriods: 'month' as enumEnergyBillPeriods,
                        currentFacilityItLoadMw: undefined,
                        doYouHaveGeneratorsOrDRups: undefined,
                        whatVoltageAreYourGeneratorsOrDrups: undefined,
                        doYouHaveStaticUps: undefined,
                        doesYourDataCentreHaveARaisedFloor: undefined,
                        hotOrColdIsleContainment: undefined,
                        anyExistingEnvironmentalMonitoring: undefined,
                        anyExistingEnvironmentalMonitoringOther: undefined,
                        isEnvironmentalMonitoringWorking: undefined,
                        areYouMonitoringTemperaturePerRack: undefined,
                        anyTemperatureAndOrHumiditySla: undefined,
                        totalSiteCoolingPowerMw: undefined,
                        coolingType: undefined,
                        doYouHaveCracOrCrahs: undefined,
                        numberOfCracOrCrahUnits: undefined,
                        approximateTotalCoolingCapacityOfTheCracsOrCrahsMW: undefined,
                        numberOfCracOrCrahUnitsOnStandbyOrOffByFault: undefined,
                        doTheCracOrCrahUnitsHaveVariableSpeedFans: undefined,
                        approximateAgeOfCracsOrCrahsInstallationYear: undefined,
                        approximateAgeOfCoolingSystemInstallationYear: undefined,
                        numberOfFloors: undefined,
                        numberOfHalls: undefined,
                        numberActiveItRacks: undefined,
                    };

                    building.myDataPropertyId = f.propertyId;
                    building.clientCode = f.clientCode;
                    building.clientFacilityId = f.clientPropertyId;
                    building.buildingName = f.propertyName;
                    building.region = f.region;
                    building.country = f.country;
                    building.city = f.city;
                    building.facility = 'Other';
                    building.otherFacility = f.propertyType;
                    building.addressLine1 = f.address1;
                    building.addressLine2 = f.address2;
                    building.county = f.stateProvince;
                    building.postCode = f.postalCode;

                    buildings.push(building);
                }
            }
        });

        formikBag.setFieldValue('buildings', buildings);
        props.onChangeRoot(`${RouteUrlBuilding(props.match.params.id)}/${buildings.length - 1}`);
    };

    const onSubmit = (site: Partial<ISiteProfile>) => {
        if (site.id) {
            props.onUpdate(site);
        } else {
            props.onCreate(site);
        }
    };

    const title =
        props.initialValues && props.initialValues.name
            ? props.initialValues.name
            : props.pageTitle;

    const isUsers = () => props.location.pathname.includes('Users');

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const isSiteForm = () => props.location.pathname === RouteUrlSite(props.match.params.id);

    const isSiteSummary = () =>
        props.location.pathname.includes(RouteUrlSiteSummary(props.match.params.id));

    const isFacility = () => props.location.pathname.includes('Building');

    const isQuestionnaire = () => props.location.pathname.includes('Questionnaire');

    const isSystemIntegration = () =>
        props.location.pathname.includes('SystemIntegration') &&
        checkPermissions([Privilege.SystemIntegrations], props.permissions);
    const getErrors = (errors) => {
        return (errors && Object.keys(errors).map((error) => {
            return (
                <>
                    {!Array.isArray(errors[error]) ?
                        (
                            <div><span>{errors[error]}</span></div>
                        )
                        :
                        errors[error].map((err, index) => {
                            return (
                                <>
                                    {err && <div className="validation-header"><strong>{translate('UpsertSite.SiteForm.Panel.Header.Facility')} #{index + 1}</strong></div>}
                                    {getErrors(err)}
                                </>
                            )
                        })
                    }
                </>
            )
        })
        )

    }
    const getBody = (formikBag) => {
        return (
            <>
                {Object.keys(formikBag.errors).some((error) => siteValidationGroup.includes(error)) ? <div className="validation-header"><strong>{translate('SideNavBar.Labels.Site')}</strong></div> : ""}
                {getErrors(formikBag.errors)}
            </>
        )
    }

    const onChangeRoot = (url: string) => {

        props.onChangeRoot(url);

    };

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            isAdminPage={true}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={`/Site/Upsert/${props.siteId}/Form/SiteDetails`}
        >
            <SiteFacilitiesToolbar onClickHelp={onClickHelp} isShowHelp={props.showHelpModule} />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.siteFacilities}
            />
            <Loader loading={props.isLoading}>
                <div className="section UpsertSitePage">
                    <div className="UpsertSiteForm">
                        <div className="container">
                            <Formik<Partial<ISiteProfile>>
                                initialValues={props.initialValues}
                                validationSchema={siteSchema}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                                validateOnBlur={true}
                                render={(formikBag) => {
                                    return (
                                        <div className="columns">
                                            <ProgressBar
                                                title={translate('UpsertSite.SiteForm.Panel.Header.SiteSections')}
                                                subtitle={translate('UpsertSite.SiteForm.Panel.Header.SiteSectionsInfo')}
                                                sections={getProgressSections(
                                                    formikBag,
                                                    props.match.params.id,
                                                    props.permissions,
                                                    translate
                                                )}
                                                onChangeRoot={(url) => onChangeRoot(url)}
                                                currentPath={props.location.pathname}
                                            />
                                            {isSiteForm() && (
                                                <SiteForm
                                                    client={props.client}
                                                    formikBag={formikBag}
                                                    onChangeRoot={props.onChangeRoot}
                                                    setErrorDialogVisible={setErrorDialogVisible}
                                                    setIsFacilityDialogVisible={setIsFacilityDialogVisible}
                                                    operatingPlatforms={props.operatingPlatforms}
                                                />
                                            )}

                                            {isSiteSummary() && (
                                                <SiteSummary
                                                    formikBag={formikBag}
                                                    onChangeRoot={props.onChangeRoot}
                                                    setErrorDialogVisible={setErrorDialogVisible}
                                                    setIsFacilityDialogVisible={setIsFacilityDialogVisible}
                                                />
                                            )}

                                            {isFacility() && (
                                                <FieldArray
                                                    name="facilities"
                                                    render={() => (
                                                        <BuildingForm
                                                            pueProjections={props.pueProjections}
                                                            onSaveEnergyBill={
                                                                props.onSubmitEnergyBill
                                                            }
                                                            shouldPublish={false}
                                                            formikBag={formikBag}
                                                            onChangeRoot={props.onChangeRoot}
                                                            setErrorDialogVisible={setErrorDialogVisible}
                                                            setIsFacilityDialogVisible={setIsFacilityDialogVisible}
                                                        />
                                                    )}
                                                />
                                            )}
                                            {isQuestionnaire() && (
                                                <Questionnaire
                                                    id={props.match.params.id}
                                                    formikBag={formikBag}
                                                    onChangeRoot={props.onChangeRoot}
                                                />
                                            )}
                                            {isUsers() && (
                                                <SiteUsers
                                                    siteId={props.match.params.id}
                                                    formikBag={formikBag}
                                                />
                                            )}
                                            {isSystemIntegration() && (
                                                <SystemIntegrationContainer formikBag={formikBag} />
                                            )}
                                            <ConfirmDialog
                                                title={translate('ActionUpsertPage.Validation.ValidationTitle')}
                                                message={getBody(formikBag)}
                                                isVisible={isErrorDialogVisible}
                                                showConfirmButton={false}
                                                onClose={() => setErrorDialogVisible(false)}
                                                onOutsideDialogClick={() => setErrorDialogVisible(false)}
                                                buttonCancelText={translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}
                                            />
                                            <FacilitySelectPage isShown={isFacilityDialogVisible}
                                                facilityId={''}
                                                existingFacilities={(formikBag?.values?.buildings) ?? []}
                                                lookupFacilities={[]}
                                                setSiteProfile={props.initialValues}
                                                onClose={() => {
                                                    setIsFacilityDialogVisible(false)
                                                }}
                                                onSubmit={(selectedFacilities) => {
                                                    addMyDataFacilities(formikBag, selectedFacilities)
                                                    setIsFacilityDialogVisible(false);
                                                }}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};
