import { IAppAction } from '../app-action';
import { IPerson } from 'models/person';
import moment from 'moment';

export enum UserProfileActions {
    LOAD_PERSON = '@@userProfile/LOAD_PERSON',
    LOAD_PERSON_FULFILLED = '@@userProfile/LOAD_PERSON_FULFILLED',
    LOAD_PERSON_REJECTED = '@@userProfile/LOAD_PERSON_REJECTED',

    UPDATE_PERSON = '@@userProfile/UPDATE_PERSON',
    UPDATE_PERSON_FULFILLED = '@@userProfile/UPDATE_PERSON_FULFILLED',
    UPDATE_PERSON_REJECTED = '@@userProfile/UPDATE_PERSON_REJECTED',

    UPDATE_LANGUAGE = '@@userProfile/UPDATE_LANGUAGE',
    UPDATE_LANGUAGE_FULFILLED = '@@userProfile/UPDATE_LANGUAGE_FULFILLED',
    UPDATE_LANGUAGE_REJECTED = '@@userProfile/UPDATE_LANGUAGE_REJECTED',

    UPDATE_IS_SHOW_FAQ = '@@userProfile/UPDATE_IS_SHOW_FAQ',
    UPDATE_IS_SHOW_FAQ_FULFILLED = '@@userProfile/UPDATE_IS_SHOW_FAQ_FULFILLED',
    UPDATE_IS_SHOW_FAQ_REJECTED = '@@userProfile/UPDATE_IS_SHOW_FAQ_REJECTED',

    SHOW_ROLE_SELECTOR_DIALOG = '@@userProfile/SHOW_ROLE_SELECTOR_DIALOG',

    UPDATE_PREFERRED_ROLE = '@@userProfile/UPDATE_PREFERRED_ROLE',
    UPDATE_PREFERRED_ROLE_FULFILLED = '@@userProfile/UPDATE_PREFERRED_ROLE_FULFILLED',
    UPDATE_PREFERRED_ROLE_REJECTED = '@@userProfile/UPDATE_PREFERRED_ROLE_REJECTED',

    LOAD_MY_ASSIGNED_SITES = '@@userProfile/LOAD_MY_ASSIGNED_SITES',
    LOAD_MY_ASSIGNED_SITES_FULFILLED = '@@userProfile/LOAD_MY_ASSIGNED_SITES_FULFILLED',
    LOAD_MY_ASSIGNED_SITES_REJECTED = '@@userProfile/LOAD_MY_ASSIGNED_SITES_REJECTED',

    SHOW_OPERATING_PLATFORM_SELECTOR_DIALOG = '@@userProfile/SHOW_OPERATING_PLATFORM_SELECTOR_DIALOG',

    UPDATE_PREFERRED_OPERATING_PLATFORM = '@@userProfile/UPDATE_PREFERRED_OPERATING_PLATFORM',
    UPDATE_PREFERRED_OPERATING_PLATFORM_FULFILLED = '@@userProfile/UPDATE_PREFERRED_OPERATING_PLATFORM_FULFILLED',
    UPDATE_PREFERRED_OPERATING_PLATFORM_REJECTED = '@@userProfile/UPDATE_PREFERRED_OPERATING_PLATFORM_REJECTED',
}

export const loadPerson = (id: string): IAppAction => ({
    type: UserProfileActions.LOAD_PERSON,
    payload: id,
});

export const updatePerson = (person: IPerson): IAppAction => ({
    type: UserProfileActions.UPDATE_PERSON,
    payload: person,
});

export const updateLanguage = (person: IPerson): IAppAction => {
    moment.locale(person.language);

    return {
        type: UserProfileActions.UPDATE_LANGUAGE,
        payload: person,
    };
};

export const updateIsShowFaq = (person: IPerson): IAppAction => {
    return {
        type: UserProfileActions.UPDATE_IS_SHOW_FAQ,
        payload: person,
    };
};

export const showRoleSelectorDialog = (showPopup: boolean): IAppAction => {
    return {
        type: UserProfileActions.SHOW_ROLE_SELECTOR_DIALOG,
        payload: showPopup,
    };
};

export const updatePreferredRole = (person: IPerson): IAppAction => {
    return {
        type: UserProfileActions.UPDATE_PREFERRED_ROLE,
        payload: person,
    };
};

export const loadMyAssignedSites = (): IAppAction => ({
    type: UserProfileActions.LOAD_MY_ASSIGNED_SITES,
});

export const showOperatingPlatformSelectorDialog = (showPopup: boolean): IAppAction => {
    return {
        type: UserProfileActions.SHOW_OPERATING_PLATFORM_SELECTOR_DIALOG,
        payload: showPopup,
    };
};

export const updatePreferredOperatingPlatform = (person: IPerson): IAppAction => {
    return {
        type: UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM,
        payload: person,
    };
};