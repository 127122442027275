import { IAuditableItem } from './auditable-item';
import { IBuilding } from './building';
import { IKeyValue } from './key-value';
import { IKeyedItem } from './keyed-item';
import { IOption } from 'components/form/fields/select-field';

export interface ISiteProfile extends IKeyedItem, IAuditableItem {
    id: string;
    name: string;
    siteCode: string;
    clientId: string;
    clientAndSiteName: string;
    serviceLines: Array<IKeyValue<string>>;
    country?: string;
    totalMainsPowerCapacity: number;
    isOwnedByClient: string;
    segment: string;
    cmms: string;
    otherCMMS?: string;
    lineOfBusiness: string;
    division: string;
    businessUnit: string;
    numOfBuildings: number;
    clientName: string;
    clientCode: string;
    isActive: boolean;
    cbrE_Operate_24x7: string;
    shiftHandoverType: ShiftHandoverType;
    buildings: Array<Partial<IBuilding>>;
    cbrE_Contract_Ref: string;
    contractRefIsReadOnly: boolean;
    siteFeatures: string[];
    isTestSite?: boolean;
    restrictAcknowledgement?: boolean;
    elogbooksBuildingIds?: Array<string>;
    operatingPlatform: string;
    assessmentSummary: ISiteAssessmentSummary;
    riskPlatform: string;
}

export interface IPersonAssignedSite {
    id: string;
    name: string;
    clientId: string;
    clientName: string;
    clientAndSiteName: string;
    country?: string;
    lineOfBusiness: string;
    division: string;
    businessUnit: string;
    operatingPlatform: string;
}

export enum ShiftHandoverType {
    Site = 'Site',
    Facility = 'Facility',
}

export const getShiftHandoverTypes: Array<IKeyValue<string>> = Object.keys(ShiftHandoverType).map(
    (key) => ({
        key,
        value: key,
    })
);

export const getOption = (site: ISiteProfile): IOption => {
    if (site === null) {
        return null;
    }

    return {
        value: site.id,
        label: site.clientAndSiteName,
    };
};

export interface ISiteInfo {
    key: string;
    value: string;
    lineOfBusiness: string;
    region: string;
    country: string;
    clientName: string;
    operatingPlatform: string;
}

export interface ImaxMinValues {
    max: number;
    min: number;
    maxMessage: string;
    minMessage: string;
}

export interface IOperatingPlatformProcess {
    operatingPlatformDropdown: string;
}

export interface IOperatingPlatformCreate {
    OperatingPlatform: string;
    processLink: string;
    shortForm: string;
}
export interface ILineOfBusiness {
    parentId: string;
    id: string;
    title: string;
}

export interface IOperatingPlatformHierarchyTracking {
    lineOFBusiness: string;
    devisionSector: string;
}

export enum OperatingPlatform {
    DataCenter = 'Data Center',
    Healthcare = 'Healthcare',
    CriticalFacility = 'Critical Facility',
}

export const getSiteOperatingPlatform = (siteId: string, lookupSites: any) => {
    let site = lookupSites.find(x => x.key === siteId);
    return site ? site.operatingPlatform : '';
}

export interface ISiteAssessmentSummary {
    facilityDescription: string;
    assessmentSummary: string;
    yearConstructed: string;
    criticalLinesOfBusiness: string;
    space: string;
    staff: string;
    contactInfo: string;
}
export const siteValidationGroup = [
    'name',
    'clientId',
    'lineOfBusiness',
    'riskPlatform',
    'operatingPlatform',
];

export enum SideNavDropdown {
    SelectPreferredOperatingPlatform = '5',
}
