import { Form, FormikProps } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { CustomSelect } from 'components/CustomSelect';
import { Control, Field, Help, Input } from 'components/form';
import { ArticlePanel } from 'components/panel';
import { Select as QuantumSelect } from 'components/select';
import { Select as QuantumSelectLabel } from 'components/selectLabel';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile, getShiftHandoverTypes, OperatingPlatform } from 'models/site-profile';
import { stringYesNoOptions } from 'routes/upsert-cerm-process-step/views/common';
import { formikFieldCss, scrollToFirstValidationError } from 'utils/form-utils';
import { checkPermission } from 'utils/permission-utils';
import { RouteUrlSiteSummary, RouteUrlBuilding } from '../../routes';
import { IUpsertSitePageRouteParams } from '../upsert-site-page';
import './site-form.scss';
import { Button, ButtonWithConfirmDialog, ConfirmDialog, FloatingButton } from 'components/v2/components';
import { IOption } from 'components/form/fields/select-field';
import { FeatureToggle } from 'components/feature-toggle/feature-toggle';
import { SiteFeatures } from 'actions/site-feature';
import { Checkbox } from 'components/form/Checkbox';
import { Privilege } from 'enums/Privilege';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { IBusinessUnitLookupModel } from '../../../../models/businessUnit-lookup-model';
import { IServiceLineLookupModel } from '../../../../models/serviceLine-lookup-model';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { IClient } from 'models/client';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IDivisions } from 'models/divisions-model';

export interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    attachments: IAttachmentModel[];
    formikBag: FormikProps<Partial<ISiteProfile>>;
    lookupSegments: ICommonLookupModel[];
    lookupCMMTypes: Array<IKeyValue<string>>;
    lookupServiceLines: IServiceLineLookupModel[];
    lookupLineOfBusiness: ICommonLookupModel[];
    clients: Array<IKeyValue<string>>;
    isSubmitting: boolean;
    permissions: Array<IKeyValue<string>>;
    lookupRiskPlatform: Array<IKeyValue<string>>;
    client: Partial<IClient>;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    setIsFacilityDialogVisible: (isDialogVisible: boolean) => void;
    operatingPlatforms: IOperatingPlatform[];
    businessUnit: IBusinessUnitLookupModel[];
    divisions: IDivisions[];
}

export interface IDispatchProps {
    loadClient: (clientId: string) => void;
    onDeleteSite: (id: string) => void;
    onChangeRoot: (routeUrl: string) => void;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
}
let mainAddress: IKeyValue<string> = { key: '', value: '' };

export const SiteForm: React.FC<IProps & IDispatchProps> = ({
    match,
    attachments,
    formikBag,
    lookupLineOfBusiness,
    lookupSegments,
    lookupServiceLines,
    lookupCMMTypes,
    clients,
    isSubmitting,
    permissions,
    lookupRiskPlatform,
    client,
    operatingPlatforms,
    setErrorDialogVisible,
    setIsFacilityDialogVisible,
    onChangeRoot: onNavigate,
    loadAttachments,
    uploadAttachment,
    onDeleteSite,
    loadClient,
    businessUnit,
    divisions
}) => {
    const shiftHandoverTypes = getShiftHandoverTypes;
    const translate = useTranslate();

    const [selectedShiftHandoverType, setShiftHandoverType] = React.useState(null);
    const [operatingPlatformChanged, setOperatingPlatformChanged] = React.useState<string>(undefined);

    React.useEffect(() => {
        if (formikBag.values.shiftHandoverType && shiftHandoverTypes) {
            const selectedValue = shiftHandoverTypes.find(
                f => f.value === formikBag.values.shiftHandoverType
            );
            setShiftHandoverType({
                value: selectedValue.value,
                label: translate(
                    'UpsertSite.SiteForm.ShiftHandoverType.'.concat(
                        selectedValue?.key?.replace(/[^\w]/g, '')
                    )
                )
            } as IKeyValue<string>);
        }
    }, [formikBag.values.shiftHandoverType]);

    const handleTestSiteChange = () => {
        formikBag.setFieldValue('isTestSite', !formikBag.values.isTestSite);
    };

    const handleRestrictAcknowledgementChange = () => {
        formikBag.setFieldValue(
            'restrictAcknowledgement',
            !formikBag.values.restrictAcknowledgement
        );
    };

    const handleShiftHandoverTypeChange = (value: IOption) => {
        formikBag.setFieldValue('shiftHandoverType', value?.value);
        setShiftHandoverType(value);
    };

    const onDeleteSiteHandler = () => {
        onDeleteSite(formikBag.values.id);
    };

    const getDivisions = () => {
        return divisions.filter(
            x =>
                x.operatingPlatform === formikBag.values.operatingPlatform && // -Data Center
                x.lineOfBusiness === formikBag.values.lineOfBusiness
        );
    };

    React.useEffect(() => {
        if (client) {
            formikBag.setFieldValue('clientCode', client.clientCode);
        } else {
            formikBag.setFieldValue('clientCode', null);
        }
    }, [client]);

    React.useEffect(() => {
        if (formikBag?.values?.clientId) {
            if (!(formikBag.values.clientId === client?.id)) {
                loadClient(formikBag.values.clientId);
            }
        }
    }, [formikBag]);

    const selectClient = (e: any) => {
        const c = clients.filter(x => x.key === e.target.selectedOptions[0].value);
        const clientId = e.target.selectedOptions[0].value;
        let clientName = e.target.selectedOptions[0].text;
        if (c.length > 0) {
            clientName = c[0].value;
        }
        formikBag.setFieldValue('clientId', clientId);
        formikBag.setFieldValue('clientName', clientName);
        formikBag.setFieldValue('client.id', clientId);
        formikBag.setFieldValue('client.name', clientName);
        loadClient(clientId);
    };

    const selectDivision = (e: any) => {
        formikBag.setFieldValue('division', e.target.selectedOptions[0].value);
        formikBag.setFieldValue('businessUnit', '');
    };

    const onServiceLineChange = (e: Array<any>) => {
        formikBag.setFieldValue('serviceLines', e);
    };

    const onPublish = () => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                formikBag.submitForm();
            } else {
                setErrorDialogVisible(true);
            }
        });
    };

    const onNavigateToSiteOverview = () => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                if (formikBag.isValid || (formikBag.values.id && !formikBag.dirty)) {
                    onNavigate(RouteUrlSiteSummary(match.params.id));
                } else {
                    scrollToFirstValidationError();
                    formikBag.submitForm();
                }
            } else {
                scrollToFirstValidationError();
            }
        });
    };

    const onNavigateToBuilding = () => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                if (formikBag.isValid || (formikBag.values.id && !formikBag.dirty)) {
                    onNavigate(`${RouteUrlBuilding(match.params.id)}/0`);
                } else {
                    scrollToFirstValidationError();
                    setErrorDialogVisible(true);
                    formikBag.submitForm();
                    onNavigate(`${RouteUrlBuilding(match.params.id)}/0`);
                }
            } else {
                setErrorDialogVisible(true);
            }
        });
    };



    const onAddBuilding = () => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                if (formikBag.isValid || (formikBag.values.id && !formikBag.dirty)) {
                    if (
                        formikBag.values.clientCode &&
                        formikBag.values.clientCode != null &&
                        formikBag.values.clientCode !== ''
                    ) {
                        setIsFacilityDialogVisible(true);
                    } else
                        onNavigate(
                            `${RouteUrlBuilding(match.params.id)}/${formikBag.values.buildings.length
                            }`
                        );
                } else {
                    scrollToFirstValidationError();
                    setErrorDialogVisible(true);
                    formikBag.submitForm();
                    onNavigate(`${RouteUrlBuilding(match.params.id)}/0`);
                }
            } else {
                if (formikBag.values.buildings.length === 0) {
                    onNavigate(
                        `${RouteUrlBuilding(match.params.id)}/${formikBag.values.buildings.length}`
                    );
                } else {
                    setErrorDialogVisible(true);
                }

            }
        });
    };

    const checkSelectedCMMS = () => {
        const isInDropdown = lookupCMMTypes.some(c => c.value === formikBag.values.cmms);
        if (
            !isInDropdown &&
            formikBag.values.otherCMMS === undefined &&
            formikBag.values.cmms !== 'Other' &&
            formikBag.values.cmms !== null
        ) {
            formikBag.setFieldValue('otherCmms', formikBag.values.cmms);
            formikBag.setFieldValue('cmms', 'Other');
        }
    };

    const addressList = () => {
        const addressArray: Array<IKeyValue<string>> = [];
        if (formikBag.values.buildings && formikBag.values.buildings.length > 0) {
            formikBag.values.buildings.forEach((building, index) => {
                const addressStrings = [];
                if (building.addressLine1) {
                    addressStrings.push(building.addressLine1);
                }
                if (building.city) {
                    addressStrings.push(building.city);
                }
                if (building.postCode) {
                    addressStrings.push(building.postCode);
                }
                if (building.country) {
                    addressStrings.push(building.country);
                }
                addressArray.push({
                    key: String(index),
                    value: `${building.buildingName + ':'} ${addressStrings.join(', ')}`,
                });
            });
        }
        return addressArray;
    };

    const onSelectAddress = (e: any) => {
        mainAddress = e.target.value;
        formikBag.values.buildings.forEach((_BUILDING, index) => {
            formikBag.setFieldValue(`buildings[${index}].isMasterAddress`, false);
        });
        formikBag.setFieldValue(`buildings[${e.target.selectedIndex - 1}].isMasterAddress`, true);
    };

    const getMainAddress = () => {
        const buildingIndex: number = formikBag.values.buildings.findIndex(b => b.isMasterAddress);
        const addresses = addressList();
        if (addresses.length > 0 && buildingIndex < 0) {
            formikBag.setFieldValue(`buildings[${addresses[0].key}].isMasterAddress`, true);
        }
        return addresses[buildingIndex] != null ? addresses[buildingIndex] : { key: '', value: '' };
    };

    mainAddress = getMainAddress();

    const doesSiteExist = () => {
        return formikBag.values.id ? true : false;
    };

    const onSelectOperatingPlatform = (operatingPlatform: string) => {
        formikBag.setFieldValue('operatingPlatform', operatingPlatform);
        formikBag.setFieldValue('lineOfBusiness', '');
        formikBag.setFieldValue('division', '');
        formikBag.setFieldValue('businessUnit', '');
        setOperatingPlatformChanged(undefined)
    };

    const getLineOfBusiness = () => {
        return lookupLineOfBusiness.filter(
            x => x.operatingPlatform === formikBag.values.operatingPlatform
        );
    };

    const getBusinessUnits = () => {
        return businessUnit.filter(
            x =>
                x.operatingPlatform === formikBag.values.operatingPlatform &&
                x.lineOfBusiness === formikBag.values.lineOfBusiness &&
                x.division === formikBag.values.division
        );
    };

    const getSegments = () => {
        const filteredSegments = lookupSegments
            .filter(x => x.operatingPlatform === formikBag.values.operatingPlatform)
            .map((c) => ({
                key: c.key,
                label: translate(
                    'UpsertSite.SiteForm.segment.'.concat(
                        c?.value?.replace(/[^\w]/g, '')
                    )
                ),
                value: c.value,
            }));

        return filteredSegments;
    };

    const getLookupRiskPlatform = () => {
        lookupRiskPlatform = lookupRiskPlatform?.map((c) => ({
            key: c.key,
            label: translate(
                'UpsertSite.SiteForm.LookupRiskPlatform.'.concat(
                    c?.value?.replace(/[^\w]/g, '')
                )
            ),
            value: c.value,
        }));

        const targetValue = formikBag?.values?.riskPlatform;

        if (targetValue) {
            lookupRiskPlatform?.forEach((platform) => {
                if (platform?.value?.toLowerCase() === targetValue?.toLowerCase()) {
                    platform.key = targetValue;
                }
            });
        }

        return lookupRiskPlatform;
    }

    const handleRiskPlatformChange = (selectedOption: any) => {
        const originalValue = lookupRiskPlatform?.find(platform =>
            platform?.value?.toLowerCase() === selectedOption?.target?.value?.toLowerCase())?.value;
        formikBag.setFieldValue('riskPlatform', originalValue);
    };

    const cbreOperateOptions = () => {
        const operateOperations = stringYesNoOptions.map((c) => ({
            key: c.key,
            label: translate(
                'UpsertSite.SiteForm.CBRE_Operate_24x7.'.concat(
                    c?.value?.replace(/[^\w]/g, '')
                )
            ),
            value: c.value,
        }))

        return operateOperations;
    }

    const getServiceLine = () => {
        let result = [];

        if (formikBag.values.operatingPlatform) {
            result = lookupServiceLines.filter(
                x => x.operatingPlatform === formikBag.values.operatingPlatform
            );
        }

        if (formikBag.values.segment &&
            formikBag.values.operatingPlatform === OperatingPlatform.Healthcare) {
            result = result
                .filter(x => x.segment === formikBag.values.segment)
                .map((x) => ({
                    key: x.key,
                    label: x.value ?
                        (translate('UpsertSite.SiteForm.ServiceLine.'.concat(x?.value?.replace(/[^\w]/g, ''))))
                        : translate('OpportunityQ.MasterForm.EmptyOption'),
                    value: x.value,
                }));
        }

        return result;
    };

    const canUploadSiteImage = checkPermission(Privilege.AllAttachmentsUpload, permissions);

    const handleSegmentChange = (value: IKeyValue<string>) => {
        formikBag.setFieldValue('segment', value?.value);
    };


    const handleCBREOperateChange = (value: IKeyValue<string>) => {
        formikBag.setFieldValue('cbrE_Operate_24x7', value?.value);
    };

    const translateValues = (values: IKeyValue<string>[]) => {
        return values?.map(x => {
            return {
                ...x,
                label: x?.value ? (translate(
                    'UpsertSite.SiteForm.ServiceLine.'.concat(
                        x?.value?.replace(/[^\w]/g, '')
                    )
                )) : translate('OpportunityQ.MasterForm.EmptyOption')
            };
        });
    }
    return (
        <ArticlePanel className="column">
            <header>
                <h1 className="title is-4">Site</h1>
                <small>{translate('UpsertSite.SiteForm.Panel.Text')}</small>
            </header>
            <Form>
                <div className="SiteForm">
                    <Field
                        isHorizontal={true}
                        htmlFor="name"
                        label={translate('Cerm.SiteSelect.Cloumns.SiteName')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="name"
                                    name="name"
                                    aria-required="true"
                                    placeholder={translate('Cerm.SiteSelect.Cloumns.SiteName')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'name')}
                                    value={formikBag.values.name}
                                    onChange={formikBag.handleChange}
                                    onBlur={event =>
                                        formikBag.setFieldValue(
                                            event.target.name,
                                            event.target.value.trim()
                                        )
                                    }
                                />
                            </Control>
                            <Help isShown={formikBag.touched.name} bulmaColor={BulmaColor.Danger}>
                                {formikBag.errors.name}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="clientId"
                        label={translate('Globals.Category.dropdown.Client')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <QuantumSelectLabel
                                    id="clientId"
                                    name="clientId"
                                    aria-required="true"
                                    options={clients}
                                    className={formikFieldCss(formikBag, 'clientId')}
                                    value={formikBag.values.clientId}
                                    onChange={selectClient}
                                    onBlur={formikBag.handleBlur}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.clientId}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.clientId}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="operatingPlatform"
                        label={translate('Globals.Category.dropdown.OP')}
                        labelSize={BulmaSize.Medium}
                    >
                        {!!operatingPlatformChanged && <ConfirmDialog
                            isVisible={!!operatingPlatformChanged}
                            title={translate('UpsertSite.SiteForm.ChangeOperatingPlatform.Title')}
                            message={<><p style={{ whiteSpace: 'pre-line' }}>{translate('UpsertSite.SiteForm.ChangeOperatingPlatform.Body')}</p></>}
                            onClose={() => setOperatingPlatformChanged(undefined)}
                            onConfirm={() => onSelectOperatingPlatform(operatingPlatformChanged)}
                            onOutsideDialogClick={() => setOperatingPlatformChanged(undefined)}
                        />}

                        <Field>
                            <Control>
                                <QuantumSelect
                                    id="operatingPlatform"
                                    name="operatingPlatform"
                                    aria-required="true"
                                    options={operatingPlatforms}
                                    className={formikFieldCss(formikBag, 'operatingPlatform')}
                                    value={formikBag.values.operatingPlatform}
                                    onChange={e => (!!formikBag.values.id && !!formikBag.values.operatingPlatform) ?
                                        setOperatingPlatformChanged(e.currentTarget.selectedOptions[0].value) :
                                        onSelectOperatingPlatform(e.currentTarget.selectedOptions[0].value)}
                                    onBlur={formikBag.handleBlur}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.operatingPlatform}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.operatingPlatform}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="lineOfBusiness"
                        label={translate('UpsertSite.SiteForm.Field.Label.LOB')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <CustomSelect
                                    id="lineOfBusiness"
                                    name="lineOfBusiness"
                                    aria-required="true"
                                    disabled={!formikBag.values.operatingPlatform}
                                    options={getLineOfBusiness()}
                                    className={formikFieldCss(formikBag, 'lineOfBusiness')}
                                    value={formikBag.values.lineOfBusiness}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.lineOfBusiness}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.lineOfBusiness}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="division"
                        label={translate('UpsertSite.SiteForm.Field.Label.Division')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <CustomSelect
                                    id="division"
                                    name="division"
                                    aria-required="false"
                                    disabled={!formikBag.values.lineOfBusiness}
                                    options={getDivisions()}
                                    className={formikFieldCss(formikBag, 'division')}
                                    value={formikBag.values.division}
                                    onChange={selectDivision}
                                    onBlur={formikBag.handleBlur}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.division}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.division}
                            </Help>
                        </Field>
                    </Field>

                    {getBusinessUnits().length > 0 && (
                        <Field
                            isHorizontal={true}
                            htmlFor="businessUnit"
                            label={translate('Globals.Label.BusinessUnit')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <CustomSelect
                                        id="businessUnit"
                                        name="businessUnit"
                                        aria-required="false"
                                        disabled={!formikBag.values.division}
                                        options={getBusinessUnits()}
                                        className={formikFieldCss(formikBag, 'businessUnit')}
                                        value={formikBag.values.businessUnit}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.businessUnit}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.businessUnit}
                                </Help>
                            </Field>
                        </Field>
                    )}
                    {getSegments().length > 0 && (
                        <Field
                            isHorizontal={true}
                            htmlFor="segment"
                            label={translate('UpsertSite.SiteForm.Field.Label.Segment')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="segment"
                                        name="segment"
                                        options={getSegments()}
                                        className={formikFieldCss(formikBag, 'segment')}
                                        value={{
                                            key: formikBag?.values?.segment,
                                            value: formikBag?.values?.segment,
                                            label: formikBag?.values?.segment ? translate(
                                                'UpsertSite.SiteForm.segment.'.concat(
                                                    formikBag?.values?.segment?.replace(/[^\w]/g, '')
                                                )
                                            ) : translate(
                                                'OpportunityQ.MasterForm.EmptyOption'
                                            )
                                        }}
                                        onChange={handleSegmentChange}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.segment}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.segment}
                                </Help>
                            </Field>
                        </Field>
                    )}
                    {formikBag.values.buildings && formikBag.values.buildings.length >= 1 && (
                        <Field
                            isHorizontal={true}
                            htmlFor="mainAddress"
                            label={translate('UpsertSite.SiteForm.Field.Label.MainAddress')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    {formikBag.values.buildings.length === 1 ? (
                                        <Field
                                            labelSize={BulmaSize.Small}
                                            isHorizontal={true}
                                            label={mainAddress.value}
                                        />
                                    ) : (
                                        <CustomSelect
                                            id="mainAddress"
                                            name="mainAddress"
                                            aria-required="true"
                                            options={addressList()}
                                            className={formikFieldCss(formikBag, 'segment')}
                                            value={mainAddress.value}
                                            onChange={onSelectAddress}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(
                                                'UpsertSite.SiteForm.Field.Label.SelectMainAddress'
                                            )}
                                        />
                                    )}
                                </Control>
                                <Help
                                    isShown={formikBag.touched.segment}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.segment}
                                </Help>
                            </Field>
                        </Field>
                    )}
                    <Field
                        isHorizontal={true}
                        htmlFor="serviceLines"
                        label={translate('UpsertSite.SiteForm.Field.Label.ServiceLine')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Select
                                    id="serviceLines"
                                    name="serviceLines"
                                    isMulti={true}
                                    options={getServiceLine()}
                                    value={translateValues(formikBag?.values?.serviceLines)}
                                    onChange={onServiceLineChange}
                                    onBlur={formikBag.handleBlur}
                                    noOptionsMessage={() => translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                    placeholder={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="cmms"
                        label={translate('UpsertSite.SiteForm.Field.Label.CMMS')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <>{checkSelectedCMMS()}</>
                            <Control>
                                <CustomSelect
                                    id="cmms"
                                    name="cmms"
                                    aria-required="true"
                                    options={lookupCMMTypes}
                                    className={formikFieldCss(formikBag, 'cmms')}
                                    value={formikBag.values.cmms}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            {formikBag.values.cmms === 'Other' && (
                                <Control>
                                    <Input
                                        id="otherCMMS"
                                        name="otherCMMS"
                                        placeholder={translate(
                                            'UpsertSite.SiteForm.Field.Label.Message'
                                        )}
                                        type="text"
                                        value={formikBag.values.otherCMMS}
                                        className={formikFieldCss(formikBag, 'otherCMMS')}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                            )}
                            <Help
                                isShown={formikBag.touched.otherCMMS}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.otherCMMS}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="riskPlatform"
                        label={translate('UpsertSite.SiteForm.Field.Label.Message2')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <QuantumSelectLabel
                                    id="riskPlatform"
                                    name="riskPlatform"
                                    options={getLookupRiskPlatform()}
                                    className={formikFieldCss(formikBag, 'riskPlatform')}
                                    value={formikBag?.values?.riskPlatform}
                                    onChange={handleRiskPlatformChange}
                                    emptyOptionsValue={translate(
                                        'OpportunityQ.MasterForm.EmptyOption'
                                    )}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.errors.riskPlatform ? true : false}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.riskPlatform}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="cbrE_Contract_Ref"
                        label={translate('UpsertSite.SiteForm.Field.Label.Message3')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="cbrE_Contract_Ref"
                                    name="cbrE_Contract_Ref"
                                    placeholder={
                                        formikBag.values.contractRefIsReadOnly
                                            ? ''
                                            : translate('UpsertSite.SiteForm.Field.Label.Message4')
                                    }
                                    type="text"
                                    readOnly={formikBag.values.contractRefIsReadOnly}
                                    value={formikBag.values.cbrE_Contract_Ref}
                                    className={formikFieldCss(formikBag, 'cbrE_Contract_Ref')}
                                    onChange={formikBag.handleChange}
                                    onBlur={event =>
                                        formikBag.setFieldValue(
                                            event.target.name,
                                            event.target.value.trim()
                                        )
                                    }
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.cbrE_Contract_Ref}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.cbrE_Contract_Ref}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="cbrE_Operate_24x7"
                        label={translate('UpsertSite.SiteForm.Field.Label.CBREOperate')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Select
                                    id="cbrE_Operate_24x7"
                                    name="cbrE_Operate_24x7"
                                    options={cbreOperateOptions()}
                                    className={formikFieldCss(formikBag, 'cbrE_Operate_24x7')}
                                    value={{
                                        key: formikBag?.values?.cbrE_Operate_24x7,
                                        value: formikBag?.values?.cbrE_Operate_24x7,
                                        label: formikBag?.values?.cbrE_Operate_24x7 ? translate(
                                            'UpsertSite.SiteForm.CBRE_Operate_24x7.'.concat(
                                                formikBag?.values?.cbrE_Operate_24x7?.replace(/[^\w]/g, '')
                                            )
                                        ) : translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )
                                    }}
                                    onChange={handleCBREOperateChange}
                                />
                            </Control>
                            <Help
                                isShown={formikBag.touched.cbrE_Operate_24x7}
                                bulmaColor={BulmaColor.Danger}
                            >
                                {formikBag.errors.cbrE_Operate_24x7}
                            </Help>
                        </Field>
                    </Field>
                    <FeatureToggle
                        feature={SiteFeatures.CDNA}
                        features={formikBag.values.siteFeatures}
                    >
                        <Field
                            isHorizontal={true}
                            htmlFor="shiftHandoverType"
                            label={translate('UpsertSite.SiteForm.Field.Label.ShiftHandoverType')}
                            labelSize={BulmaSize.Medium}
                            infoText={translate('UpsertSite.SiteForm.Field.Message')}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="shiftHandoverType"
                                        name="shiftHandoverType"
                                        options={shiftHandoverTypes?.map((c) => ({
                                            label: c?.key ? translate(
                                                'UpsertSite.SiteForm.ShiftHandoverType.'.concat(
                                                    c?.key?.replace(/[^\w]/g, '')
                                                )
                                            ) : translate('OpportunityQ.MasterForm.EmptyOption'),
                                            value: c.value,
                                        }))}
                                        value={selectedShiftHandoverType}
                                        className={formikFieldCss(formikBag, 'shiftHandoverType')}
                                        onChange={handleShiftHandoverTypeChange}
                                        placeholder={translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </FeatureToggle>
                    <Field
                        isHorizontal={true}
                        htmlFor="restrictAcknowledgement"
                        label={translate('UpsertSite.SiteForm.Field.Message2')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Checkbox
                                    id="restrictAcknowledgement"
                                    label=""
                                    checked={formikBag.values.restrictAcknowledgement}
                                    onChange={handleRestrictAcknowledgementChange}
                                />
                            </Control>
                        </Field>
                    </Field>
                    {checkPermission(Privilege.DemoSite, permissions) && (
                        <Field
                            isHorizontal={true}
                            htmlFor="isTestSite"
                            label={translate('UpsertSite.SiteForm.Field.IsTestSite')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Checkbox
                                        id="isTestSite"
                                        label=""
                                        checked={formikBag.values.isTestSite}
                                        onChange={handleTestSiteChange}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    )}
                    <Field
                        isHorizontal={true}
                        htmlFor="uploadSiteImage"
                        label={
                            canUploadSiteImage
                                ? translate('UpsertSite.SiteForm.Field.UploadSiteImage')
                                : translate('UpsertSite.SiteForm.Field.SiteImage')
                        }
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <div className="documents">
                                    <Attachments
                                        id={formikBag.values.id}
                                        entity={Entity.SiteImage}
                                        attachments={attachments}
                                        loadAttachments={loadAttachments}
                                        uploadAttachment={uploadAttachment}
                                        isReadOnly={!canUploadSiteImage}
                                        isSingleFile={true}
                                        isImage={true}
                                    />
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <div className="SiteForm__actions">
                        <div className="action-group">
                            <NavLink className="button button-cancel" to={`/Site`}>
                                {translate('Globals.Label.Cancel')}
                            </NavLink>
                            {formikBag.values.id && checkPermission('SiteDelete', permissions) && (
                                <ButtonWithConfirmDialog
                                    title="Archive"
                                    buttonText={translate('RiskActionTab.Button.Archive')}
                                    buttonType="archive"
                                    onConfirm={onDeleteSiteHandler}
                                    message={translate('UpsertSite.SiteForm.Field.Message3')}
                                    isLoading={isSubmitting}
                                />
                            )}
                        </div>
                        <div className="action-group">
                            {formikBag.values.buildings &&
                                formikBag.values.buildings.length >= 2 &&
                                doesSiteExist() && (
                                    <Button
                                        onClick={onNavigateToSiteOverview}
                                        isLoading={isSubmitting}
                                    >
                                        {formikBag.values.buildings.length === 0
                                            ? translate('UpsertSite.SiteForm.Field.SiteSummary')
                                            : translate('SchedulerWeek.Button.NextDay.buttonText')}
                                    </Button>
                                )}
                            {formikBag.values.buildings &&
                                formikBag.values.buildings.length > 0 &&
                                doesSiteExist() && (
                                    <Button onClick={onNavigateToBuilding} isLoading={isSubmitting}>
                                        {translate('UpsertSite.SiteForm.Field.FirstFacility')}
                                    </Button>
                                )}
                            {doesSiteExist() && (
                                <Button onClick={onAddBuilding} isLoading={isSubmitting}>
                                    {translate('UpsertSite.BuildingForm.Field.AddFacility')}
                                </Button>
                            )}

                            {doesSiteExist() && (
                                <FloatingButton
                                    onClick={onPublish}
                                    isLoading={isSubmitting}
                                    float={formikBag.dirty}
                                    tooltip={translate('Globals.Label.PleaseClick')}
                                >
                                    {translate('Globals.Label.Save')}
                                </FloatingButton>
                            )}

                            {!doesSiteExist() && (<FloatingButton
                                onClick={onAddBuilding}
                                isLoading={isSubmitting}
                                float={formikBag.dirty}
                            >
                                {translate('UpsertSite.BuildingForm.Field.AddFacility')}
                            </FloatingButton>)}
                        </div>
                    </div>
                </div>
            </Form>
        </ArticlePanel>
    );
};
