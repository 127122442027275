import appConfig from './config-helper';
import authentication from '@cbre/platform-client-auth-library'

const config = appConfig();
export const initializeAuthentication = () => {
    if (config.REACT_APP_FEATURE_FLAG_OKTA === "okta") {
        authentication.okta.initialize({
            issuer: config.REACT_APP_AUTH_ISSUER_OKTA,
            clientId: config.REACT_APP_AUTH_CLIENT_ID_OKTA,
            redirectUri: window.location.origin,
            autoRenew: true,
            scopes: ['openid', 'email', 'profile', 'offline_access'],
        });
    } else {
        authentication.b2c.initialize({
            instance: config.REACT_APP_AUTH_INSTANCE,
            tenant: config.REACT_APP_AUTH_TENANT,
            signInPolicy: config.REACT_APP_AUTH_SIGNINPOLICY,
            applicationId: config.REACT_APP_AUTH_APPID,
            scopes: [config.REACT_APP_AUTH_SCOPE],
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
        });
    }
}

export const getAuthenticationPlatform = () => {
    if (config.REACT_APP_FEATURE_FLAG_OKTA === "okta") {
        return authentication.okta;
    } else {
        return authentication.b2c;
    }
};