import moment from 'moment';
import { combineReducers } from 'redux';
import { BuildingActions, BuildingActionTypes } from 'actions/building-actions';
import { QuestionnaireActions, QuestionnaireActionTypes } from 'actions/questionnaire-actions';
import { UpsertSiteActionTypes } from '../../actions/upsert-site-actions';
import { ISiteProfile } from '../../models/site-profile';
import { IEnergyBill } from 'models/energy-bill';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import { IAppAction } from 'actions/app-action';
import { ClientActions } from 'actions/actions-v2/client-action-v2';
import { IClient } from 'models/client';
import { genericSort } from 'utils/sort-utils';
import { getDateNow } from 'utils/date-utils';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { ExternalBuildingsActions } from 'actions/actions-v2/external-buildings-action-v2';
import { ISiteState } from 'state/upsert-site-state';

export const SITE_INITIAL_STATE: ISiteProfile = {
    id: '',
    name: '',
    siteCode: '',
    clientId: '',
    clientAndSiteName: '',
    serviceLines: [],
    totalMainsPowerCapacity: 0,
    isOwnedByClient: '',
    segment: '',
    cmms: '',
    lineOfBusiness: '',
    division: '',
    businessUnit: '',
    numOfBuildings: 0,
    clientName: '',
    clientCode: '',
    isActive: true,
    cbrE_Operate_24x7: '',
    buildings: [],
    cbrE_Contract_Ref: '',
    contractRefIsReadOnly: false,
    siteId: '',
    bucket: 'Site',
    shard: 'Site',
    eTag: '',
    createdDateTime: getDateNow(),
    lastUpdatedDateTime: getDateNow(),
    createdByPersonId: '',
    lastUpdatedByPersonId: '',
    siteFeatures: [],
    shiftHandoverType: null,
    isTestSite: false,
    operatingPlatform: '',
    riskPlatform: '',
    assessmentSummary: {
        facilityDescription: '',
        assessmentSummary: '',
        yearConstructed: '',
        criticalLinesOfBusiness: '',
        space: '',
        staff: '',
        contactInfo: '',
    },
};

export const INITIAL_STATE: ISiteState = {
    isInitialising: true,
    site: SITE_INITIAL_STATE,
    isSubmitting: false,
    clients: [],
    pueProjection: null,
    selectedMonth: null,
    shouldLoadBenchmarkData: true,
    attachments: [],
    externalBuildings: [],
    relationships: [],
};

const isSubmitting = (state = INITIAL_STATE.isSubmitting, action: IAppAction) => {
    switch (action.type) {
        case SiteActions.DELETE_SITE:
        case SiteActions.LOAD_SITE:
        case SiteActions.CREATE_SITE:
        case SiteActions.UPDATE_SITE:
        case SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY:
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS:
            return true;
        case SiteActions.LOAD_SITE_FULFILLED:
        case SiteActions.LOAD_SITE_REJECTED:
        case SiteActions.CREATE_SITE_FULFILLED:
        case SiteActions.UPDATE_SITE_FULFILLED:
        case SiteActions.CREATE_SITE_REJECTED:
        case SiteActions.UPDATE_SITE_REJECTED:
        case SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY_FULFILLED:
        case SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY_REJECTED:
        case SiteActions.DELETE_SITE_FULFILLED:
        case SiteActions.DELETE_SITE_REJECTED:
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_FULFILLED:
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_REJECTED:
            return false;

        default:
            return state;
    }
};

const site = (state = INITIAL_STATE.site, action: IAppAction) => {
    switch (action.type) {
        case SiteActions.DELETE_BUILDING:
            return {
                ...state,
                buildings: state.buildings.filter((building) => building.id !== action.payload.id),
            };
        case SiteActions.LOAD_SITE_INITIAL_VALUES:
            return SITE_INITIAL_STATE;
        case SiteActions.LOAD_SITE_FULFILLED:
        case SiteActions.UPDATE_SITE_FULFILLED:
        case SiteActions.CREATE_SITE_FULFILLED:
            return action.payload ? action.payload : state;
        case SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY_FULFILLED:
            return {
                ...state,
                assessmentSummary: action.payload,
            };
        case SiteActions.RESET_SITE:
            return SITE_INITIAL_STATE;
        default:
            return state;
    }
};

export const pueProjection = (
    state: ISiteState['pueProjection'] = INITIAL_STATE.pueProjection,
    action: BuildingActionTypes | QuestionnaireActionTypes
) => {
    switch (action.type) {
        case BuildingActions.LOAD_PUE_BENCHMARKS_FULFILLED:
            return action.payload.model;
        case QuestionnaireActions.SUBMIT_MODEL_FULFILLED:
            return action.payload;
        case BuildingActions.LOAD_PUE_BENCHMARKS_CANCELLED:
        case BuildingActions.LOAD_PUE_BENCHMARKS_REJECTED:
            return null;
        default:
            return state;
    }
};

export const shouldLoadBenchmarkData = (
    state: ISiteState['shouldLoadBenchmarkData'] = INITIAL_STATE.shouldLoadBenchmarkData,
    action: BuildingActionTypes | UpsertSiteActionTypes
) => {
    switch (action.type) {
        case BuildingActions.LOAD_PUE_BENCHMARKS_CANCELLED:
        case BuildingActions.LOAD_PUE_BENCHMARKS_REJECTED:
        case BuildingActions.LOAD_PUE_BENCHMARKS_FULFILLED:
            return false;
        default:
            return state;
    }
};

const getIndexFromRoute = () => {
    const str = window.location.pathname;
    const n = str.lastIndexOf('/');
    // tslint:disable-next-line:radix
    return parseInt(str.substring(n + 1));
};

export const selectedMonth = (
    state: ISiteState['selectedMonth'] = INITIAL_STATE.selectedMonth,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.LOAD_SITE_FULFILLED:
            const siteModel = action.payload;
            const buildings = siteModel ? siteModel.buildings : [];
            if (buildings && buildings.length > 0) {
                const index = getIndexFromRoute();
                if (index >= 0) {
                    const energyBills = buildings[index] && buildings[index].energyBills;
                    if (energyBills && energyBills.length > 0) {
                        return energyBills
                            .slice()
                            .sort((b: IEnergyBill, a: IEnergyBill) =>
                                moment(a.date).diff(moment(b.date))
                            )[0].date;
                    }
                }
            }
            return state;
        case BuildingActions.CHANGE_PUE_CHART_MONTH:
            return action.payload.month !== undefined ? action.payload.month : state;
        default:
            return state;
    }
};

export const clients = (
    state: ISiteState['clients'] = INITIAL_STATE.clients,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientActions.LOAD_CLIENTS_FULFILLED:
            if (action.payload) {
                const clientsReposne = action.payload.map((client: IClient) => ({
                    key: client.id,
                    value: client.name,
                    label: client.clientCode == '' || client.clientCode == null ? client.name : client.name + '*',
                }));
                return genericSort('value', clientsReposne, true);
            }
            return state;
        default:
            return state;
    }
};

export const isInitialising = (
    state: ISiteState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.LOAD_SITE:
            return true;
        case SiteActions.LOAD_SITE_FULFILLED:
            return false;
        default:
            return state;
    }
};

const attachments = (
    state: ISiteState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SITE:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SITE:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;

        default:
            return state;
    }
};

const externalBuildings = (state = INITIAL_STATE.externalBuildings, action: IAppAction) => {
    switch (action.type) {
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS:
            return INITIAL_STATE.externalBuildings;
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_FULFILLED:
            return action.payload ? action.payload : state;
        case ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_REJECTED:
            return state;
        default:
            return state;
    }
};

export const relationships = (
    state: ISiteState['relationships'] = INITIAL_STATE.relationships,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.LOAD_RELATIONSHIPS_FULFILLED:
            return action.payload;
        case SiteActions.LOAD_RELATIONSHIPS:
        case SiteActions.LOAD_RELATIONSHIPS_REJECTED:
            return [];
        default:
            return state;
    }
};

export const siteUpsertReducer = combineReducers<ISiteState>({
    isInitialising,
    site,
    isSubmitting,
    pueProjection,
    selectedMonth,
    clients,
    shouldLoadBenchmarkData,
    attachments,
    externalBuildings,
    relationships,
});
