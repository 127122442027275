import { Control, Field, Help } from 'components/form';
import { Select as QuantumSelect } from 'components/select';
import { Checkbox } from 'components/form/Checkbox';
import { Loader } from 'components/loader';
import { Modal } from 'components/modal';
import { Button } from 'components/v2/components';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { Formik, FormikProps } from 'formik';
import { IKeyValue } from 'models';
import { ISiteProfile } from 'models/site-profile';
import * as React from 'react';
import { object } from 'yup';
import { IFacility } from 'models/facility-model';
import { ISiteMyDataFacilityAssignment } from 'reducers/manageRoles/manageRoles-grid';
import { IBuilding } from 'models/building';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    isShown: boolean;
    isLoading?: boolean;
    facilityId: string;
    lookupFacilities: Array<IKeyValue<string>>;
    existingFacilities: Partial<IBuilding>[];
    setSiteProfile?: Partial<ISiteProfile>;
    disableClosedAndCompleted?: boolean;
    facilities: IFacility[];
    isValidatingMyDataFacility: boolean;
    siteMyDataFacilityAssignments: ISiteMyDataFacilityAssignment[];
    onClose: () => void;
    onSubmit: (facilities: string[]) => void;
}

export interface IDispatchProps {
    loadFacility: () => void;
    validateMyDataFacilitySites: (siteId: string, propertyIds: string[]) => void;
}

export const FacilitySelectModal: React.FC<IProps & IDispatchProps> = ({ isLoading, ...props }) => {
    const [facilities, setFacilities] = React.useState<IFacility[]>([])
    const [selectedFacilities, setSelectedFacilities] = React.useState<string[]>([])
    const [countries, setCountries] = React.useState<Array<IKeyValue<string>>>([])
    const [regions, setRegions] = React.useState<Array<IKeyValue<string>>>([])
    const [selectedCountry, setSelectedCountry] = React.useState()
    const [selectedRegion, setSelectedRegion] = React.useState()

    React.useEffect(() => {
        if (props.facilities) {
            let fac = props.facilities.filter(x => props.existingFacilities.findIndex(y => !(y.isActive != null && y.isActive == false) && y.myDataPropertyId == x.propertyId) == -1)
            fac = fac.filter(x => x.clientCode == props.setSiteProfile?.clientCode)
            let f: IFacility[] = fac.filter(function (elem, pos, self) {
                return self.findIndex(x => x.region == elem.region) == pos
            });
            let c: Array<IKeyValue<string>> = f.map((item) => {
                return {
                    key: item.region,
                    value: item.region
                }
            })
            setRegions(c)
        }
        else {
            setRegions([])
        }
    }, [props.facilities, props.setSiteProfile.clientCode]);

    React.useEffect(() => {
        if (props.facilities) {
            let fac = props.facilities.filter(x => props.existingFacilities.findIndex(y => !(y.isActive != null && y.isActive == false) && y.myDataPropertyId == x.propertyId) == -1)
            fac = fac.filter(x => x.clientCode == props.setSiteProfile?.clientCode)

            let f: IFacility[] = fac.filter(function (elem, pos, self) {
                return selectedRegion && elem.region == selectedRegion
                    && self.findIndex(x => x.country == elem.country) == pos
            });
            let c: Array<IKeyValue<string>> = f.map((item) => {
                return {
                    key: item.country,
                    value: item.country
                }
            })
            setCountries(c)
        }
        else {
            setCountries([])
        }
    }, [selectedRegion, props.setSiteProfile.clientCode]);


    React.useEffect(() => {
        let fac = props.facilities.filter(x => props.existingFacilities.findIndex(y => !(y.isActive != null && y.isActive == false) && y.myDataPropertyId == x.propertyId) == -1)
        let c: IFacility[] = fac.filter((x) => x.country == selectedCountry
            && x.region == selectedRegion && x.clientCode == props.setSiteProfile.clientCode)
        setFacilities(c)
        setSelectedFacilities([])
    }, [selectedCountry, selectedRegion]);

    const translate = useTranslate();
    const onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        setSelectedFacilities([])
        setSelectedCountry(null)
        setSelectedRegion(null)
        props.onClose();
    };

    const onSubmit = () => {
        let f = [...selectedFacilities]
        props.validateMyDataFacilitySites("09c1681b-c768-4d87-b4a2-432c67f92d4c", f)
    };

    const [isSaving, setIsSaving] = React.useState(false)
    const [siteFacilities, setSiteFacilities] = React.useState<ISiteMyDataFacilityAssignment[]>([])

    React.useEffect(() => {
        if (props.isValidatingMyDataFacility == true) {
            setIsSaving(true)
        }
        else
            if (isSaving == true) {
                setIsSaving(false)
                if (props.siteMyDataFacilityAssignments.length == 0) {
                    let f = [...selectedFacilities]
                    props.onSubmit(f);
                    setSelectedFacilities([])
                    setSelectedCountry(null)
                    setSelectedRegion(null)
                    setSiteFacilities([])
                } else {
                    setSiteFacilities(props.siteMyDataFacilityAssignments)
                }
            }
    }, [props.isValidatingMyDataFacility, props.siteMyDataFacilityAssignments]);

    const getSiteName = (propertyId): string => {
        let site = siteFacilities?.find(x => x.propertyId == propertyId)
        if (site)
            return translate('UpsertSite.FacilitySelectModel.Message2') + site.siteName + "."
        return ''
    }

    const SetProcessStepResultSchema = object().shape({
    });


    const isFacilitySelected = (facilityId) => {
        return selectedFacilities.findIndex(x => x == facilityId) != -1
    }

    const addRemoveFacilitySelected = (facilityId) => {
        let index = selectedFacilities.findIndex(x => x == facilityId)
        if (index != -1) {
            let f = selectedFacilities.filter(x => x != facilityId)
            setSelectedFacilities(f)
        }
        else {
            let f = [...selectedFacilities]
            f.push(facilityId)
            setSelectedFacilities(f)
        }
    }

    const facilityMessage = () => {
        if (selectedCountry == undefined || selectedRegion == undefined) {
            return <p className="label">{translate('UpsertSite.FacilitySelectModel.Paragraph.Text')}</p>
        } else {
            if (facilities && facilities.length == 0) {
                return <p className="label">{translate('UpsertSite.FacilitySelectModel.Paragraph2.Text')}</p>
            }
        }
        return <></>
    }

    const renderForm = (formikBag: FormikProps<ISiteProfile>) => {
        if (!formikBag.values && formikBag.initialValues.buildings?.length > 0) {
            const s = props.lookupFacilities.find((x) => x.key === formikBag.initialValues.buildings[0].clientFacilityId);
            if (s) {
                formikBag.setFieldValue('facilityIds', [s.value]);
            }
        }

        return (
            <Loader loading={isLoading || props.isValidatingMyDataFacility}>
                <form onSubmit={formikBag.handleSubmit}>
                    <div className="content">
                        <div className="columns">
                            <div className="column is-12 label">
                                {translate('UpsertSite.FacilitySelectModel.Loader.Text')}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6 label">
                                <Field
                                    isHorizontal={true}
                                    htmlFor="regionId"
                                    label={translate('GainAccess.Labels.Regions')}
                                    labelSize={BulmaSize.Medium}
                                >
                                    <Field>
                                        <Control>
                                            <QuantumSelect
                                                id="regionId"
                                                name="regionId"
                                                aria-required="true"
                                                options={regions}
                                                //className={formikFieldCss(formikBag, 'facilityId')}
                                                value={selectedRegion ?? ''}
                                                onChange={(e: any) => {
                                                    setSelectedRegion(e.target.selectedOptions[0].value)
                                                }}
                                                onBlur={formikBag.handleBlur}
                                                emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                            />
                                        </Control>
                                    </Field>
                                </Field>
                            </div>
                            <div className="column is-6 label">
                                <Field
                                    isHorizontal={true}
                                    htmlFor="countryId"
                                    label={translate('LinkedBuildings.Country')}
                                    labelSize={BulmaSize.Medium}
                                >
                                    <Field>
                                        <Control>
                                            <QuantumSelect
                                                id="countryId"
                                                name="countryId"
                                                aria-required="true"
                                                options={countries}
                                                //className={formikFieldCss(formikBag, 'facilityId')}
                                                value={selectedCountry ?? ''}
                                                onChange={(e: any) => {
                                                    setSelectedCountry(e.target.selectedOptions[0].value)
                                                }}
                                                onBlur={formikBag.handleBlur}
                                                emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                            />
                                        </Control>
                                        <Help
                                            isShown={formikBag.touched.clientId}
                                            bulmaColor={BulmaColor.Danger}
                                        >
                                            {formikBag.errors.clientId}
                                        </Help>
                                    </Field>
                                </Field>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-1 label">
                                {translate('ActionDetails.Lable.4')}
                            </div>
                            <div className="column is-11 label">
                                {translate('UpsertSite.FacilitySelectModel.FaclityName')}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-1">

                            </div>
                            <div className="column is-11">
                            </div>
                        </div>
                        <div className="facilities-container-rows">
                            {facilities && facilities.map((facility, key) => {
                                return (
                                    <div className="columns" key={"columns_facility_" + key}>
                                        <div className="column is-1" key={`individual_checkbox_${key}`}>
                                            <Checkbox
                                                id={`'facility_${key.toString()}`}
                                                key={key}
                                                checked={isFacilitySelected(facility.propertyId)}
                                                onChange={() => { addRemoveFacilitySelected(facility.propertyId) }}
                                            />
                                        </div>
                                        <div
                                            key={"facilityId_value_" + facility.propertyId}
                                            className="column is-11"
                                        >
                                            {facility.propertyName}
                                            <span className='is-danger'>
                                                {getSiteName(facility.propertyId)}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <br />
                    <div className="columns">
                        <div className="column is-12">
                            {facilityMessage()}
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                            {translate('UpsertSite.FacilitySelectModel.Message')}
                        </div>
                    </div>
                    <div className="SiteForm__actions">
                        <div className="action-group">
                            <Button
                                id="btnCancelAction"
                                buttonType="cancel"
                                onClick={onClose}
                                isLoading={isLoading}
                            >
                                {translate('FacilityPopup.Button.Cancel')}
                            </Button>
                        </div>

                        <div className="action-group">
                            <Button
                                id="btnSaveAction"
                                type="submit"
                                isLoading={isLoading}
                                disabled={
                                    !(selectedFacilities?.length > 0)
                                }
                            >
                                {translate('FacilityPopup.Button.Add')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    </div>
                </form>
            </Loader>
        );
    };

    return (
        <Modal modalId="facilitySelectModal" isShown={props.isShown}>
            <Formik<Partial<ISiteProfile>>
                initialValues={props.setSiteProfile}
                validationSchema={SetProcessStepResultSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </Modal>
    )
};

FacilitySelectModal.defaultProps = {
    setSiteProfile: {
        siteId: '',
        buildings: [],
    }
};
