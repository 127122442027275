import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { object, string } from 'yup';
import { Button, Panel } from 'components/v2/components';
import { Control, Field, Help, Input } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { IPerson, IPersonToSite } from 'models/person';
import { formikFieldCss } from 'utils/form-utils';
import actions from './actions';
import selectors from './selector';
import { NavLink } from 'react-router-dom';
import { Loader } from 'components/loader';
import { getUserId } from 'helpers/helpers';
import Page from 'components/v2/page/Page';
import { NavLanguageDropdown } from 'components/nav/NavLanguageDropdown';
import { ILanguageDropdownItem } from 'models/nav-lanuage';
import useTranslate from 'translations/translation-utils';
import { IPersonAssignedSite } from 'models/site-profile';
import _ from 'lodash';
import { AssignedSites } from 'components/siteSelector/selected-sites';

export interface IProps extends RouteComponentProps<{}> {
    person: IPerson;
    linesOfBusiness: Array<IKeyValue<string>>;
    divisions: Array<IKeyValue<string>>;
    businessUnits: Array<IKeyValue<string>>;
    myAssignedSites: IPersonAssignedSite[];
    relationships: IPersonToSite[];
    languageItem: ILanguageDropdownItem[];
    language: string;
    isLoading: boolean;
    loadPersonProfile: (id: string) => void;
    updatePerson: (person: IPerson) => void;
    updateLanguage: (person: IPerson) => void;
    loadMyAssignedSites(): void;
    loadRelationships: (personId: string) => void;
}
const PersonProfilePage: React.FC<IProps> = (props) => {
    let formik: FormikProps<IPerson>;
    const translate = useTranslate();

    const personValidationSchema = object<Partial<IPerson>>().shape({
        firstName: string().trim().required(translate('UserRequest.PersonUpsertRequest.Index.EnterFirstName')),
        lastName: string().trim().required(translate('UserRequest.PersonUpsertRequest.Index.EnterLastName')),
        emailAddress1: string()
            .trim()
            .required(translate('UserRequest.PersonUpsertRequest.Index.EnterEmailAddress'))
            .email(translate('PersonProfilePage.Email.ErrorMessage')),
        personReference: string()
            .trim()
            .matches(/^[Cc]?[0-9]+$/, translate('Validations.EmpIdNumericStartWithC'))
            .required(translate('UserRequest.PersonUpsertRequest.Index.EnterEmployeeId'))
            .nullable(true),
    });

    const personValidationClientSchema = object<Partial<IPerson>>().shape({
        firstName: string().trim().required(translate('UserRequest.PersonUpsertRequest.Index.EnterFirstName')),
        lastName: string().trim().required(translate('UserRequest.PersonUpsertRequest.Index.EnterLastName')),
        emailAddress1: string()
            .trim()
            .required(translate('UserRequest.PersonUpsertRequest.Index.EnterEmailAddress'))
            .email(translate('PersonProfilePage.Email.ErrorMessage')),
        personReference: string().matches(
            /^[Cc]?[0-9]+$/,
            translate('Validations.EmpIdNumericStartWithC')
        ),
    });

    const [chosenValidationSchema, setChosenValidationSchema] = React.useState(personValidationClientSchema);
    const myRef = React.useRef(null);
    const params: any = useParams();

    React.useEffect(() => {
        if (params.specialParameter && params.specialParameter == "ChangeLanguage") {
            executeScroll();
        }
    }, [props.location.key])

    React.useEffect(() => {
        if (props.person && props.person.roles.length === 1 && props.person.roles.filter((role) => role.value === 'Client').length > 0) {
            setChosenValidationSchema(personValidationClientSchema);
        } else {
            setChosenValidationSchema(personValidationSchema);
        }

        const element = document.getElementById('language');

        if (element !== null) {
            element.scrollIntoView();
        }
    }, [props.person]);

    React.useEffect(() => {
        if (!getUserId()) {
            return;
        }

        props.loadPersonProfile(getUserId());
        props.loadRelationships(getUserId());
        props.loadMyAssignedSites();

        const element = document.getElementById('language');

        if (element !== null) {
            element.scrollIntoView();
        }

    }, [getUserId()]);

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const onSubmit = (person: IPerson) => {
        props.updatePerson(person);
    };

    const onSave = () => {
        formik.setFieldValue('isLanguageChange', false);
        formik.submitForm();
    };

    const getAccessRightsDropdownList = (levelList: any, storedList: any) => {
        return levelList
            .filter(x => storedList.includes(x.key))
            .map(x => x.label)
            .join(', ')
    };

    const renderForm = (formikBag: FormikProps<IPerson>) => {
        formik = formikBag;

        const rolesList = formikBag.values.roles &&
            formikBag.values.roles
                .map((x) => x.value)
                .join(', ');

        const operatingPlatformList = formikBag.values.accessRights.operatingPlatforms &&
            formikBag.values.accessRights.operatingPlatforms
                .map(x => x)
                .join(', ');

        const linesOfbusinessList = formikBag.values.accessRights.linesOfBusiness &&
            getAccessRightsDropdownList(props.linesOfBusiness, formikBag.values.accessRights.linesOfBusiness);

        const divisionList = formikBag.values.accessRights.divisions &&
            getAccessRightsDropdownList(props.divisions, formikBag.values.accessRights.divisions);

        const businessUnitList = formikBag.values.accessRights.businessUnits &&
            getAccessRightsDropdownList(props.businessUnits, formikBag.values.accessRights.businessUnits);

        const clientList = formikBag.values.accessRights.clients &&
            _.uniq(props.myAssignedSites
                .filter(x => formikBag.values.accessRights.clients.includes(x.clientId))
                .map(x => x.clientName))
                .join(', ');

        const siteList = formikBag.values.accessRights.sites &&
            props.myAssignedSites
                .filter(x => formikBag.values.accessRights.sites.includes(x.id))
                .map(x => x.clientAndSiteName)
                .join(', ');

        const assignedSites = props.myAssignedSites
            .filter(s => props.relationships.find(r => s.id === r.siteId));

        return (
            <Loader loading={props.isLoading}>
                <form id="personUpsertForm" onSubmit={formikBag.handleSubmit}>
                    <Field
                        isHorizontal={true}
                        htmlFor="title"
                        label={translate('GainAccess.Labels.FirstName')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    aria-required="true"
                                    placeholder={translate('GainAccess.Labels.FirstName')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'firstName')}
                                    value={formikBag.values.firstName}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={formikBag.touched.firstName}>
                                {formikBag.errors.firstName}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="lastName"
                        label={translate('GainAccess.Labels.LastName')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    aria-required="true"
                                    className={formikFieldCss(formikBag, 'lastName')}
                                    placeholder={translate('GainAccess.Labels.LastName')}
                                    value={formikBag.values.lastName}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={formikBag.touched.lastName}>
                                {formikBag.errors.lastName}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="title"
                        label={translate('GainAccess.Labels.PreferredNames')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="preferredName"
                                    name="preferredName"
                                    aria-required="true"
                                    placeholder={translate('PersonProfilePage.Field1.Message')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'preferredName')}
                                    value={formikBag.values.preferredName}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="personReference"
                        label={translate('GainAccess.Labels.HRPersonReferenceID')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="personReference"
                                    name="personReference"
                                    aria-required="true"
                                    placeholder={translate('GainAccess.Labels.HRPersonReferenceID')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'personReference')}
                                    value={formikBag.values.personReference}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={formikBag.touched.personReference}
                            >
                                {formikBag.errors.personReference}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="businessTitle"
                        label={translate('GainAccess.Labels.JobTitle')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="businessTitle"
                                    name="businessTitle"
                                    aria-businessTitle="true"
                                    placeholder={translate('PersonProfilePage.Field2.Message')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'businessTitle')}
                                    value={formikBag.values.businessTitle}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="title"
                        label={translate('GainAccess.Labels.CBREEmailAddress')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="emailAddress1"
                                    name="emailAddress1"
                                    aria-required="true"
                                    placeholder={translate('PersonProfilePage.Field3.Message')}
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={formikBag.values.emailAddress1}
                                    onChange={formikBag.handleChange}
                                    disabled={true}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={formikBag.touched.emailAddress1}
                            >
                                {formikBag.errors.emailAddress1}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="telephone1"
                        label={translate('GainAccess.Labels.MainNumber')}
                        labelSize={BulmaSize.Medium}
                        className="is-"
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="telephone1"
                                    name="telephone1"
                                    aria-required="true"
                                    placeholder="02074033582"
                                    type="number"
                                    className={formikFieldCss(formikBag, 'telephone1')}
                                    value={formikBag.values.telephone1}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                />
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                {formikBag.errors.telephone1}
                            </Help>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="telephone2"
                        label={translate('PersonProfilePage.Field4.Message')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="telephone2"
                                    name="telephone2"
                                    aria-required="true"
                                    placeholder="02074033581"
                                    type="number"
                                    className={formikFieldCss(formikBag, 'telephone2')}
                                    value={formikBag.values.telephone2}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                />
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                {formikBag.errors.telephone2}
                            </Help>
                        </Field>
                    </Field>
                    <div ref={myRef} className="profile-language">
                        <Field
                            isHorizontal={true}
                            id="language"
                            htmlFor="language"
                            label={translate('PersonProfilePage.Field5.label')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <NavLanguageDropdown
                                        className="profile__language"
                                        dropdownItems={props.languageItem}
                                        personlanguage={props.language}
                                        updateLanguage={props.updateLanguage}
                                        loadPersonProfile={props.loadPersonProfile}
                                        person={props.person}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>

                    <div className="title is-4 section-title">
                        {translate('GainAccess.Labels.Roles')}
                    </div>

                    <Field
                        isHorizontal={true}
                        htmlFor="rolesList"
                        label={translate('PersonProfilePage.Field6.label')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="rolesList"
                                    name="rolesList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={rolesList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <div className="title is-4 section-title">{translate('PersonProfilePage.AccessRights')}</div>

                    <Field
                        isHorizontal={true}
                        htmlFor="operatingPlatformList"
                        label={translate('PersonProfilePage.Field7.label')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="operatingPlatformList"
                                    name="operatingPlatformList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={operatingPlatformList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="lineOfbusinessList"
                        label={translate('Globals.Label.LineOfBusiness')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="lineOfbusinessList"
                                    name="lineOfbusinessList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={linesOfbusinessList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="divisionList"
                        label={translate('Globals.Label.Division')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="divisionList"
                                    name="divisionList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={divisionList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="sitesList"
                        label={translate('Globals.Label.BusinessUnit')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="businessUnitList"
                                    name="businessUnitList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={businessUnitList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="clientList"
                        label={translate('Globals.Category.dropdown.Client')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="clientList"
                                    name="clientList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={clientList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="siteList"
                        label={translate('Globals.Label.Site')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="siteList"
                                    name="siteList"
                                    aria-required="true"
                                    type="text"
                                    className={formikFieldCss(formikBag, 'emailAddress1')}
                                    value={siteList}
                                    disabled={true}
                                />
                            </Control>
                        </Field>
                    </Field>

                    <div className="title is-4 section-title">{translate('Globals.Label.Sites')}</div>

                    {assignedSites && assignedSites.length > 0 ? (
                        <AssignedSites selectedSites={assignedSites} />
                    ) : (
                        <span className="icon is-small is-loading is-centered">
                            <Loader loading={true} />
                        </span>
                    )}

                    <div className="action-groups">
                        <div className="action-group">
                            <NavLink
                                id="person_upsert_publish"
                                className="button is-outline is-medium is-pulled-right"
                                to={'/'}
                            >
                                {translate('Globals.Label.Cancel')}
                            </NavLink>
                        </div>
                        <div className="action-group">
                            <Button id="person_upsert_publish" type="button" onClick={onSave}>
                                {translate('Globals.Label.Save')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Loader>
        );
    };

    return (
        <Page title={translate('SideNavBar.Labels.Profile')} isAdminPage={true}>
            <Panel title={translate('RiskRegisterUpsertPage.Tabs.Details')} bodyPadding={true}>
                <Formik<Partial<IPerson>>
                    initialValues={props.person}
                    onSubmit={onSubmit}
                    validationSchema={chosenValidationSchema}
                    enableReinitialize={true}
                    render={renderForm}
                />
            </Panel>
        </Page>
    );
};
export default withRouter(connect(selectors, actions)(PersonProfilePage));
