import moment from 'moment';

export function formatDateToDateControl(isoDate: string) {
    if (isoDate) {
        return moment(isoDate).format('YYYY-MM-DD');
    }
}

export function formatDate(isoDate: string) {
    return moment(isoDate).format('DD MMM YYYY');
}
export function getDateNow() {
    return moment(new Date(Date.now())).utc().format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

export function getDatePartOfDateTime(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function formatTime(isoDate: string) {
    return moment(isoDate).format('HH:mm');
}

export function formatDateTime(isoDate: string | Date): string {
    return moment(isoDate).format('DD MMM YYYY HH:mm');
}

export function formatDate_Time(isoDate: Date) {
    return moment(isoDate).format('DD-MM-YYYY  HH:mm');
}

export function formatDateString(
    isoDate: Date | string,
    formatString: string = 'DD MMM YY',
    defaultEmptyString: string = 'N/A'
): string {
    if (!isValidDate(isoDate)) {
        return defaultEmptyString;
    }

    return moment(isoDate).format(formatString);
}

export function formatDateToUniversalDateTime(isoDate: string | Date) {
    return moment(isoDate).format('DD MMM YYYY - h:mm a');
}

export function addMonthsToNow(monthsToAdd: number) {
    return moment(new Date()).add(monthsToAdd, 'month');
}

export function addDaysToNow(daysToAdd: number) {
    return moment(new Date()).add(daysToAdd, 'day');
}

export const getDateFromNow = (amount: number, unitOfTime: string): string => {
    const date = unitOfTime === 'day' ? addDaysToNow(amount) : addMonthsToNow(amount);
    return date.toISOString();
};

export const isValidDate = (date: Date | string): boolean => {
    const mDate = moment(date);
    if (!mDate.isValid) {
        return false;
    }

    const minDate = moment.utc('0001-01-01');
    return mDate.isAfter(minDate);
};

export const isToday = (date: Date | moment.Moment): boolean => {
    const mDate = moment(date);
    return mDate.isSame(new Date(), 'day');
};

export const isTodayOrFutureDate = (date: Date | moment.Moment): boolean => {
    const mDate = moment(date);
    const today = new Date();
    return mDate.isSame(today, 'day') || mDate.isAfter(today, 'day');
};

export const getTimezoneDiffrence = (date: Date): Date => {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
};

export const convertStringToDate = (date: string) => {
    const newDate = new Date(date + 'T00:00');
    return newDate;
};

export const getDateDiffInDays = (firstDate: Date | string, secondDate: Date | string) => {
    return moment.duration(moment(firstDate).diff(secondDate)).asDays();
};

export const getDateDiffFromNowInDays = (date: Date | string) => {
    const now = moment(new Date()).toDate();

    return getDateDiffInDays(date, now);
};
